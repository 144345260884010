import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { AccountCircle } from "@mui/icons-material";
import { handleImageLoaded } from "../../../services/utils";
import { t } from "i18next";

function PaymentDetails(props) {
  return (
    <Box>
      <Box
        className={"card"}
        sx={{
          m: "0 var(--detailsBoxPadding)"
        }}
      >
        <Box>
          {props.payment.tools.length} {t("paymentProvider_countTools")}
        </Box>
        <Box>Tools {import.meta.env.VITE_CUSTOMER_NAME}</Box>
      </Box>
      <Box>
        {props.payment.tools.map((tool) => {
          return (
            <Box
              className={"card"}
              sx={{
                m: "var(--detailsBoxPadding)",
              }}
              key={tool.tool_synced.id}
            >
              {tool.logo !== null ? (
                <Avatar
                  alt={
                    import.meta.env.VITE_API_URL +
                    "/assets/" +
                    tool.tool_synced.name
                  }
                  src={"assets/loading.svg"}
                  onLoad={(event) =>
                    handleImageLoaded(
                      event,
                      tool.tool_synced.logo.id,
                      "?width=40&height=40"
                    )
                  }
                />
              ) : (
                <AccountCircle sx={{ fontSize: 40 }} />
              )}
              <Typography
                variant={"h5"}
                sx={{ lineHeight: "var(--lineHeight)" }}
              >
                {tool.tool_synced.name}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ flexGrow: 1, minWidth: "50%" }}>
                  <Typography variant={"subtitle2"}>
                    {t("paymentProvider_category")}
                  </Typography>
                  <Typography variant={"h6"}>
                    {tool.tool_synced.category.translations[0].name}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, minWidth: "50%" }}>
                  <Typography variant={"subtitle2"}>
                    {t("paymentProvider_subcategory")}
                  </Typography>
                  <Typography variant={"h6"}>
                    {tool.tool_synced.subcategory
                      ? tool.tool_synced.subcategory.translations[0].name
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

PaymentDetails.propTypes = {
  payment: PropTypes.shape({
    tools: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number,
        id: PropTypes.string,
        name: PropTypes.string,
        logo: PropTypes.arrayOf(PropTypes.object),
        category: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcategory: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ),
  }),
  selectedPayment: PropTypes.number,
  onClick: PropTypes.func,
};

export default PaymentDetails;
