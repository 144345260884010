import React from "react";
import { Button, Grid, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import {
  API_addFileToTool, API_getTool, API_removeFile, API_removeToolFileConnection,
  API_uploadFile,
} from "../../../services/backendService";
import {
  Add,
  Payment
} from "@mui/icons-material";
import { format } from "date-fns";
import {handleImageLoaded, downloadItem, getCssValue} from "../../../services/utils";
import { t } from "i18next";

function ToolPayment(props) {
  React.useEffect(() => {
    if (document.getElementById("paymentImage")) {
      document.getElementById("paymentImage").src = "assets/loading.svg";
    }
  }, [props.tool]);

  const addInvoice = (event) => {
    props.setLoading(true);
    API_uploadFile(event.target.files[0], "5ffa2eb9-c5b4-40d7-bc25-3ee7501b923f").then((invoiceId) => {
      API_addFileToTool(props.tool.id, invoiceId, event.target.files[0].name).then(() => {
        API_getTool(props.tool.id).then(r => {
          if (r) {
            let updatedTools = JSON.parse(JSON.stringify(props.allTools));
            for (let i = 0; i < props.allTools.length; i++) {
              if (props.allTools[i].id === props.tool.id) {
                updatedTools[i] = r;
                props.setTools(updatedTools);
              }
            }
            props.setLoading(false);
          }
        })
      })
    });
  };

  const removeInvoice = (toolFileId, fileId) => {
    props.setLoading(true);
    API_removeToolFileConnection(toolFileId).then(() => {
      API_removeFile(fileId).then(() => {
        API_getTool(props.tool.id).then(r => {
          let updatedTools = JSON.parse(JSON.stringify(props.allTools));
          for (let i = 0; i < updatedTools.length; i++) {
            if (props.allTools[i].id === props.tool.id) {
              updatedTools[i] = r;
              props.setTools(updatedTools);
            }
          }
          props.setLoading(false);
        })
      })
    })
  };

  return (
    <Box className={"card"}
         id={"invoices"}
         sx={{
           m: "var(--detailsBoxPadding)",
           p: "0 var(--detailsBoxPadding) 0 0",
         }}
    >
      <Box id={"invoicesBoxTop"}></Box>
      <Typography variant={"h5"} sx={{ p: "var(--detailsBoxPadding)" }}>
        {t('toolsServices_payment_header')}
      </Typography>
      <Box
        sx={{
          m: "0 var(--detailsBoxPadding) 0 0",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box
            sx={{
              width: "var(--detailsBoxWidth)",
              p: "var(--detailsBoxPadding)",
            }}
            className={"gridElement"}
          >
            {
              !!props.tool.payment && props.tool.payment.logo ? <Box
                id={"paymentImage"}
                  component="img"
                  sx={{
                    width:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                  }}
                  alt={props.tool.payment.name}
                  src={"assets/loading.svg"}
                  onLoad={(event) =>
                    handleImageLoaded(event, props.tool.payment.logo.id,
                      "?width=" + (getCssValue("--detailsBoxWidth") - getCssValue("--detailsBoxPadding")))
                  }
                /> : <Payment sx={{ fontSize: 200, color: "rgb(220,220,220)" }} />
            }
          </Box>
          <Box
            sx={{
              width: "var(--detailsBoxWidth)",
              p: "var(--detailsBoxPadding)",
            }}
            className={"gridElement"}
          >
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "var(--detailsBoxPadding) 0 0 0",
              }}
              label={t("toolsServices_paymentServiceProvider")}
              type={"string"}
              value={!!props.tool.payment ? props.tool.payment.id : "empty"}
              onChange={(event) => {
                props.update( "{payment: {id:\"" + event.target.value + "\"}}", true);
              }}
              select
            >
              {props.payments.map((payment) => (
                <MenuItem value={payment.id} key={payment.id}>
                  {payment.name}
                </MenuItem>
              ))}
              <MenuItem
                value={"empty"}
                sx={{ display: "none" }}
                disabled={true}
              >
                {t('select')}
              </MenuItem>
            </TextField>
            {!!props.tool.payment_amount &&
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                  m: "var(--detailsBoxPadding) 0 0 0",
                }}
                label={t("toolsServices_nextPaymentAmount")}
                type={"tel"}
                value={props.tool.payment_amount.toLocaleString(undefined, {minimumFractionDigits: 2,})}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                disabled
              />
            }
          </Box>
          <Box
            sx={{
              width:
                '"calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))"',
              p: "var(--detailsBoxPadding)"
            }}
            className={"gridElement"}
          >
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "var(--detailsBoxPadding) 0 0 0",
              }}
              label={t("toolsServices_paymentMethod")}
              type={"tel"}
              value={
                props.tool.payment_method !==
                null
                  ? props.tool.payment_method
                  : "empty"
              }
              onChange={(event) => {
                props.update("{ payment_method:\"" + event.target.value + "\"}");
              }}
              select
            >
              <MenuItem value={"Credit card"}>{t('toolsServices_creditCard')}</MenuItem>
              <MenuItem value={"Bank transfer"}>{t('toolsServices_bankTransfer')}</MenuItem>
              <MenuItem value={"Direct debit"}>{t('toolsServices_directDebit')}</MenuItem>
              <MenuItem value={"free of charge"}>{t('toolsServices_freeOfCharge')}</MenuItem>
              <MenuItem value={"unknown"}>{t('toolsServices_unknown')}</MenuItem>
              <MenuItem
                value={"empty"}
                sx={{ display: "none" }}
                disabled={true}
              >
                {t('select')}
              </MenuItem>
            </TextField>
            <br/>
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "var(--detailsBoxPadding) 0 0",
              }}
              label={t("toolsServices_nextPayment")}
              type={"string"}
              value={props.tool.payment_date ? format(new Date(props.tool.payment_date),"dd.MM.yyyy") : "-"}
              disabled
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: "var(--detailsBoxPadding) 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
          }}
        >
          <input
            type="file"
            hidden
            onChange={addInvoice}
            id={"uploadFileInput"}
          />
          <label htmlFor={"uploadFileInput"}>
            <Button variant="outlined" startIcon={<Add />} component={"span"}>{t('toolsServices_invoice')}</Button>
          </label>
          <Grid container spacing={2} sx={{pt: "var(--detailsBoxPadding)"}}>
            {props.tool.invoice.length > 0 &&
              props.tool.invoice.map(function (
                object
              ) {
                return (
                  !!object.directus_files_id && object.directus_files_id.id && <Box key={object.directus_files_id.id}
                       sx={{p: "var(--detailsBoxPadding)"}}
                        // onMouseEnter={() => setShowDeleteIcon(i)}
                        // onMouseLeave={() => setShowDeleteIcon("hide")}
                  >
                    <Box>
                      <Box
                        onClick={() => downloadItem(object.directus_files_id.id, object.directus_files_id.title)}
                        component="img"
                        sx={{
                          cursor: "pointer",
                          height:
                            "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                          width:
                            "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                          objectFit: "cover"
                        }}
                        alt={object.directus_files_id.title}
                        src={"assets/loading.svg"}
                        onLoad={(event) =>
                          handleImageLoaded(event, object.directus_files_id.id,
                            "?height=" + (getCssValue("--detailsBoxWidth") - getCssValue("--detailsBoxPadding")))
                        }
                      />
                      <Typography>{object.directus_files_id.title}</Typography>
                      <Button onClick={() => removeInvoice(object.id, object.directus_files_id.id)}>{t('delete')}</Button>
                    </Box>
                  </Box>
                )
              })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ToolPayment;

ToolPayment.propTypes = {
  payments: PropTypes.array,
  tool: PropTypes.shape({
    id: PropTypes.string,
  }),
  setTools: PropTypes.func,
  tools: PropTypes.array,
  payment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape({
        Name: PropTypes.string,
        "Bild Bezahlung": PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string,
          })
        ),
        Bezahldatum: PropTypes.string,
        Zahlbetrag: PropTypes.number,
      }),
    })
  ),
  update: PropTypes.func,
  setLoading: PropTypes.func
};