import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./authentication.css";
import { API_getUser, API_login } from "../../services/backendService";
import Notification from "../notification";
import { useNavigate } from "react-router-dom";
import i18n, { t } from "i18next";
import PropTypes from "prop-types";

const Login = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [stayLoggedin, setStayLoggedin] = useState(true);
  const navigate = useNavigate();

  const login = () => {
    if (email === null || email === "") {
      setNotify({
        isOpen: true,
        message: t("toast_loginNoEmailError"),
        type: "error",
      });
    } else if (password === null || password === "") {
      setNotify({
        isOpen: true,
        message: t("toast_loginNoPasswordError"),
        type: "error",
      });
    } else {
      API_login(email, password, setNotify).then((loginResult) => {
        if (stayLoggedin === false) {
          props.startLogoutTimer();
        }
        if (!!loginResult && loginResult.data) {
          localStorage.setItem("loggedin", true);
          localStorage.setItem("access_token", loginResult.data.access_token);
          localStorage.setItem("refresh_token", loginResult.data.refresh_token);
          localStorage.setItem("stayLoggedin", stayLoggedin);
          API_getUser().then((userResult) => {
            if (userResult.data) {
              props.setUserData(userResult.data.users_me);
              if (userResult.data.users_me.language !== "de-DE") {
                i18n.changeLanguage(userResult.data.users_me.language);
                props.forceUpdate();
              }
              props.handleLogin(true);
              navigate("/dashboard");
            }
          });
        }
      });
    }
  };

  const handleChange = (event) => {
    setStayLoggedin(event.target.checked);
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          overflowX: "hidden",
          position: "relative",
          backgroundColor: "white"
        }}
      >
        <Box id={"form"} sx={{ width: { xs: "100%", sm: "33%" }, backgroundColor: "white" }}>
          <Box className={"innerBox"} sx={{p: "var(--detailsBoxPadding)", maxWidth: "400px"}}>
            <Typography variant={"h5"}>{t("login_header")}</Typography>
            <Typography variant={"body2"} sx={{ pb: "1.5rem" }}>
              {t("login_subheader")}
            </Typography>
            <FormControl>
              <TextField
                label={t("email")}
                type={"text"}
                variant={"outlined"}
                className={"textfield"}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete={"email"}
              ></TextField>
              <TextField
                label={t("password")}
                variant={"outlined"}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              ></TextField>
              <Box className={"notes"}>
                <FormControlLabel
                  checked={stayLoggedin}
                  onChange={handleChange}
                  control={<Checkbox />}
                  label={t("login_stayLoggedIn")}
                />
                {/*<Typography*/}
                {/*  variant={"body2"}*/}
                {/*  sx={{ cursor: "pointer", color: "primary.main" }}*/}
                {/*  onClick={() => navigate("/forgotPassword")}*/}
                {/*>*/}
                {/*  {t("login_forgotPasswort")}*/}
                {/*</Typography>*/}
              </Box>
              <Button
                variant={"contained"}
                onClick={login}
              >
                {t("login_button")}
              </Button>
              <Box className={"notes"}>
                <Typography variant={"body2"} sx={{ m: "0px 0.5rem 0px 0px" }}>
                  {t("login_newOnPlatform")}
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{ cursor: "pointer", color: "primary.main" }}
                  onClick={() => navigate("/register")}
                >
                  {t("login_createAccount")}
                </Typography>
              </Box>
            </FormControl>
          </Box>
        </Box>
        <Box
          id={"image"}
          sx={{
            width: { xs: "0px", sm: "77%" },
            display: { xs: "none", sm: "auto" },
            backgroundColor: "var(--background)",
          }}
        >
          <Box sx={{textAlign: "center"}}>
            <Box
              component="img"
              sx={{
                maxWidth: "65%",
                maxHeight: "65%",
                objectFit: "cover",
                marginBottom: "3em"
              }}
              alt=""
              src={
                import.meta.env.VITE_API_URL +
                "/assets/0e240a91-f608-4f69-adf4-5ebeb3e2804e?height=100"
                }
            />
            <Typography variant={"h5"}>{t('login_imageHeader')}</Typography>
            <Box sx={{marginBottom: "3em"}}>{t('login_imageSubheader')}</Box>
            <Box
              component="img"
              sx={{
                maxWidth: "80%",
                maxHeight: "80%",
                objectFit: "cover",
              }}
              alt=""
              src={
                import.meta.env.VITE_API_URL +
                "/assets/b7579b26-4490-4277-865d-12ead96fa049?height=" +
                window.innerWidth
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Login.propTypes = {
  setUserData: PropTypes.func,
  handleLogin: PropTypes.func,
};

export default Login;
