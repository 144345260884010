import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CostsPerX from "./components/costsPerX";
import ChartCostsPerMonth from "./components/chartCostsPerMonth";
import Calendar from "./components/calendar";
import Timeline from "./components/timeline";
import PropTypes from "prop-types";
import PaymentBarChart from "./components/paymentBarChart";
import { t } from "i18next";
import { Scrollbars } from 'react-custom-scrollbars';

const Dashboard = (props) => {
  function getDurationOfContract(duration) {
    switch (duration) {
      case "monthly":
        return 1
      case "oneYear":
        return 12
      case "twoYears":
        return 24
    }
  }

  return (
    <Scrollbars>
      <Box
        sx={{
          p: "0 var(--listsPadding) var(--listsPadding) var(--listsPadding)",
        }}
      >
        <Typography variant={"h5"} sx={{ lineHeight: "var(--lineHeight)" }}>
          {t("dashboard_header_saas")}
        </Typography>
        {props.tools && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CostsPerX
                  costs={props.tools.map((value) => {
                    return value.costs_per_year;
                  })}
                  description={t("dashboard_costsPerYear")}
                  backgroundColor={"#ffdce5"}
                  currencySign={"€"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CostsPerX
                  costs={props.tools.map((x) => x.costs_per_month)}
                  description={t("dashboard_costsPerMonth")}
                  backgroundColor={"#ffdce5"}
                  currencySign={"€"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CostsPerX
                  costs={props.tools.map((x) => x.costs_per_year * 0.125)}
                  description={t("dashboard_savingsPotentialPerYear")}
                  backgroundColor={"#c7fdcf"}
                  currencySign={"€"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CostsPerX
                  costs={[props.tools.length]}
                  description={t("dashboard_registeredTools")}
                  backgroundColor={"#d0f0fd"}
                />
              </Grid>
            </Grid>
            <ChartCostsPerMonth
              cloudBased={props.tools.filter(function (el) {
                return (
                  el.costs_per_month > 0 && el.type_of_tool === "Cloud based"
                );
              })}
              hosting={props.tools.filter(function (el) {
                return (
                  el.costs_per_month > 0 &&
                  el.type_of_tool === "Hosting / Cloud Provider"
                );
              })}
            />
            <Typography variant={"h5"} sx={{ lineHeight: "var(--lineHeight)" }}>
              {t("dashboard_header_paymentDates")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Calendar
                  data={props.tools.map((x) => {
                    let event = {};
                    event.title = x.tool_synced.name;
                    event.start = new Date(x.start);
                    event.end = new Date(x.end);
                    event.color = x.tool_synced.category.color;
                    event.duration = getDurationOfContract(x.duration_of_contract);
                    event.status = x.status;
                    return event;
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PaymentBarChart
                  options={{ maintainAspectRatio: false }}
                  data={props.tools
                    .filter(function (el) {
                      return el.costs_per_month > 0;
                    })
                    .sort((a, b) => (a.payment_date > b.payment_date ? 1 : -1))}
                />
              </Grid>
            </Grid>
            <Typography variant={"h5"} sx={{ lineHeight: "var(--lineHeight)" }}>
              {t("dashboard_header_contractDurations")}
            </Typography>
            <Timeline
              data={props.tools.map((x) => {
                let event = {};
                event.title = x.tool_synced.name;
                event.start = new Date(x.start);
                event.end = new Date(x.end);
                event.color = x.tool_synced.category.color;
                event.duration_of_contract = x.duration_of_contract;
                event.status = x.status;
                event.sortOrder = Math.ceil(new Date(x.end).getTime()-new Date().getTime()/86400000);
                return event;
              })}
            />
          </>
        )}
      </Box>
    </Scrollbars>
  );
};

Dashboard.propTypes = {
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
      length: PropTypes.number,
    })
  ),
};

export default Dashboard;
