import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Scrollbars } from "react-custom-scrollbars";
import ToolMarketplaceList from "./components/toolMarketplaceList";
import ToolMarketplaceDetails from "./components/toolMarketplaceDetails";
import ToolMarketplaceHeader from "./components/toolMarktplaceHeader";

const ToolMarketplace = (props) => {

  const [filterName, setFilterName] = useState("");
  const [allListItemsActive, setAllListItemsActive] = useState(false);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [list, setList] = useState(null);

  React.useEffect(() => {
    let list = [];
    let alreadyAddedCategory = [];
    let initialNodes = [];
    for (let i = 0; i < props.data.length; i++) {
      const data = props.data[i];
      if (!alreadyAddedCategory.includes(data.categoryId)) {
        initialNodes.push(data.categoryId);
        let children = [];
        let alreadyAddedSubcategory = [];
        for (let j = 0; j < props.data.length; j++) {
          let sub = props.data[j];
          if (sub.categoryId === data.categoryId && !!sub.subcategoryId && !alreadyAddedSubcategory.includes(sub.subcategoryId)) {
            let matchingTools = [];
            for (let k = 0; k < props.allTools.length; k++) {
              const tool = props.allTools[k];
              if (tool.category.id === data.categoryId && (!!tool.subcategory && tool.subcategory.id === sub.subcategoryId)) {
                matchingTools.push(tool);
              }
            }
            initialNodes.push(data.categoryId + "#" + sub.subcategoryId);
            children.push({
              id: data.categoryId + "#" + sub.subcategoryId,
              name: sub.subcategory,
              parent: data.categoryId,
              tools: matchingTools
            });
            alreadyAddedSubcategory.push(sub.subcategoryId);
          }
        }
        let toolsWithoutSubcategory = [];
        for (let k = 0; k < props.allTools.length; k++) {
          let tool = props.allTools[k];
          if (!tool.subcategory && tool.category.id === data.categoryId) {
            tool.visible = true;
            toolsWithoutSubcategory.push(tool);
          }
        }
        list.push({
          id: data.categoryId,
          name: data.category,
          children: children,
          tools: toolsWithoutSubcategory
        });
        alreadyAddedCategory.push(data.categoryId);
      }
    }
    setSelectedNodes(initialNodes);
    setList(list);
  }, []);

  return (
    <>
      <ToolMarketplaceHeader filterName={filterName} setFilterName={setFilterName} setAllListItemsActive={setAllListItemsActive} isLoggedin={props.isLoggedin} />
      <Box sx={{position: "relative", height: props.isLoggedin ? "calc(100% - var(--lineHeight) - 16px)" : "calc(100% - var(--lineHeight) - 20px - 16px)", pl: "8px"}}>
        <Box sx={{position: "absolute", width: { xs: "100%", sm: "20%" }, overflow: "scroll", top: 0, bottom: "var(--detailsBoxPadding)", left: "var(--detailsBoxPadding)"}} className={"hideScrollbar"}>
          <Scrollbars autoHide>
            {!!list && <ToolMarketplaceList data={list}
                                            setData={setList}
                                            selectedNodes={selectedNodes}
                                            setSelectedNodes={setSelectedNodes}
                                            filterName={filterName}
                                            setAllListItemsActive={setAllListItemsActive}
                                            allListItemsActive={allListItemsActive}
            />}
          </Scrollbars>
        </Box>
        <Box sx={{position: "absolute", width: { xs: "0%", sm: "calc(80% - var(--detailsBoxPadding))" }, overflow: "scroll", top: 0, bottom: 0, right: 0}} className={"hideScrollbar"}>
          {/*{props.employees && props.employees.length > 0 && (*/}
          <Scrollbars>
            {!!list && <ToolMarketplaceDetails
              list={list}
              tools={props.tools}
              setTools={props.setTools}
              selectedNodes={selectedNodes}
              allTools={props.allTools}
              setLoading={props.setLoading}
              filterName={filterName}
              isLoggedin={props.isLoggedin}
            />}
          </Scrollbars>
          {/*)}*/}
        </Box>
      </Box>
    </>
  );
};

ToolMarketplace.propTypes = {
  data: PropTypes.array,
};

export default ToolMarketplace;
