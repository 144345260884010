import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  BarController,
} from "chart.js";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const ChartWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

const BarChart = (props) => {
  ChartJS.register(LinearScale, CategoryScale, BarElement, BarController);

  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderRadius: 4,
      },
    ],
  };
  if (!!props.data && props.data.length) {
    for (let i = 0; i < props.data.length; i++) {
      const toolsData = props.data[i];
      data.datasets[0].data.push({
        id: toolsData.tool_synced.name,
        nested: { value: toolsData.costs_per_year },
      });
      data.datasets[0].backgroundColor.push(toolsData.tool_synced.category.color);
    }
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: datasets[0].data[i].id,
              fillStyle: datasets[0].backgroundColor[i],
            }));
          },
        },
      },
      // tooltip: {
      //     callbacks: {
      //         label: function(tooltipItem) {
      //             return "Gesamte Kosten pro Jahr pro Tool: " + Number(tooltipItem.label);
      //         },
      //         title: function(tooltipItem) {
      //             return "Toolname: " + tooltipItem[0].dataset.title[tooltipItem[0].dataIndex]
      //             // return "gallo titel" + tooltipItem[0].dataset[tooltipItem[0].datasetIndex]
      //         }
      //     }
      // }
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          maxRotation: 0,
          callback: function (value) {
            return "€" + value;
          },
        },
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
        offset: true,
      },
    },
    parsing: {
      xAxisKey: "id",
      yAxisKey: "nested.value",
    },
  };

  return (
    <Box
      className={"card"}
    >
      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      <ChartWrapper>
        <Chart type="bar" data={data} options={options} />
      </ChartWrapper>
    </Box>
  );
};

BarChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      costs_per_year: PropTypes.number,
      tool_synced: PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.object,
      }),
    })
  ),
};

export default withTheme(BarChart);
