import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import { t } from "i18next";
import { Button } from "@mui/material";

function DynamicTextButton(props) {
  const [addButtonWidth, setAddButtonWidth] = useState(0);

  function getAddButtonWidth() {
    return !!document.getElementById("boxWithHeaderAndAddButton") ? document.getElementById("boxWithHeaderAndAddButton").offsetWidth : 0;
  }

  const isElementLoaded = async selector => {
    while ( document.querySelector(selector) === null) {
      await new Promise( resolve =>  requestAnimationFrame(resolve) )
    }
    return document.querySelector(selector);
  };

  useEffect(() => {
    function handleResize() {
      setAddButtonWidth(getAddButtonWidth());
    }

    isElementLoaded('#boxWithHeaderAndAddButton').then(() => {
      setAddButtonWidth(getAddButtonWidth());
      window.addEventListener('resize', handleResize);
    });
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Button variant="outlined"
            id={"addButton"}
            startIcon={addButtonWidth > 275 ? <AddCircleOutlineRounded /> : ""}
            onClick={() => {
              props.functions()
            }}>
      {addButtonWidth > 275 ? t("employees_add") : <AddCircleOutlineRounded />}
    </Button>
  );
}

DynamicTextButton.propTypes = {
  functions: PropTypes.func,
};

export default DynamicTextButton;