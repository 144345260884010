import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { handleImageLoaded, handleImageLoadedWithoutCredentials } from "../../../services/utils";
import { CheckCircleOutline } from "@mui/icons-material";

function ToolBox(props) {

  function getCheckCircleColor(id) {
    for (let i = 0; i < props.tools.length; i++) {
      if (props.tools[i].tool_synced.id === id) {return "#04A24C"}
    }
    return "#F0F0F0"
  }

  return (<Box className={"card"}
               sx={{m: "0 var(--listsPadding) var(--listsPadding) 0", cursor: "pointer",
                    border: !!props.tools && getCheckCircleColor(props.tool.id) === "#04A24C" ? "1px solid var(--themeMainColor)" : "1 px solid transparent",
                    maxWidth: "var(--detailsBoxWidth)", minWidth: "var(--detailsBoxWidth)"
              }}
               onClick={() => props.setSidebarData(props.tool)}
    >
      <Box sx={{display: "flex", height: "30px", alignItems: "center", mb: "calc(var(--listsPadding) / 2)"}}>
        <Box
          component="img"
          loading={"lazy"}
          sx={{ height: 25, width: 25, pr: "5px" }}
          alt={props.tool.name}
          src={"assets/loading.svg"}
          onLoad={(event) =>
          {
            if (!!props.tools) {
              handleImageLoaded(
                event,
                props.tool.logo.id,
                "?width=25&height=25"
              )
            } else {
              handleImageLoadedWithoutCredentials(
                event,
                props.tool.logo.id,
                "?width=25&height=25"
              )
            }
          }
          }
        />
        <Typography variant={"h6"} noWrap sx={{width: "176px", maxWidth: "176px"}}>{props.tool.name}</Typography>
        {!!props.tools && <CheckCircleOutline sx={{color: getCheckCircleColor(props.tool.id)}} />}
      </Box>
    {!!props.tool.translations_shortDescription[0] && <Typography sx={{
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
      fontSize: "0.9rem",
      lineHeight: "1.3rem"
    }}>{props.tool.translations_shortDescription[0].shortDescription}</Typography>}
      {/*<Box sx={{display: "flex"}}>*/}
      {/*  <Button variant={"text"}*/}
      {/*          sx={{width: "var(--detailsBoxWidth)", mr: "calc(var(--listsPadding) / 2)", height: "35px", padding: 0}}*/}
      {/*          onClick={() => addTool(props.tool.id)}*/}
      {/*  >{t('add')}</Button>*/}
      {/*  <Button variant={"text"}*/}
      {/*          sx={{width: "var(--detailsBoxWidth)", ml: "calc(var(--listsPadding) / 2)", eight: "35px", padding: 0}}*/}
      {/*          onClick={() => !!props.tool.url_web ? window.open(props.tool.url_web,'_blank') : ""}*/}
      {/*          disabled={!props.tool.url_web}*/}
      {/*  >{t('passwordPrivacy_toWebsite')}</Button>*/}
      {/*</Box>*/}
    </Box>
  );
}

ToolBox.propTypes = {
};

export default ToolBox;