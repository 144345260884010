import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import "./authentication.css";
import React, { useState } from "react";
import Notification from "../notification";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const ForgotPassword = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);

  const themeColor = "deeppink";

  function resetPassword() {
    console.log(email);
    // API_requestPasswordReset(email).then(() => {
    //   setNotify({
    //     isOpen: true,
    //     message:
    //       "Wir haben dir gerade eine E-Mail geschickt um dein Passwort zu resetten.",
    //     type: "success",
    //   });
    //   navigate("/login");
    // });
  }

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Box id={"form"} sx={{ width: { xs: "100%", sm: "33%" } }}>
          <Box className={"innerBox"}>
            <Typography variant={"h5"}>{t("forgotPassword_header")}</Typography>
            <Typography variant={"body2"} sx={{ pb: "1.5rem" }}>
              {t("forgotPassword_subheader")}
            </Typography>
            <FormControl>
              <TextField
                label={t("email")}
                variant={"outlined"}
                className={"textfield"}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
              <Button
                variant={"contained"}
                sx={{ backgroundColor: themeColor }}
                onClick={resetPassword}
              >
                {t("forgotPassword_button")}
              </Button>
              <Box className={"notes"}>
                <Typography variant={"body2"} sx={{ m: "0px 0.5rem 0px 0px" }}>
                  {t("forgotPassword_newOnPlatform")}
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{ cursor: "pointer", color: themeColor }}
                  onClick={() => navigate("/login")}
                >
                  {t("forgotPassword_backToLogin")}
                </Typography>
              </Box>
            </FormControl>
          </Box>
        </Box>
        <Box
          id={"image"}
          sx={{
            width: { xs: "0px", sm: "77%" },
            display: { xs: "none", sm: "auto" },
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
            src={
              import.meta.env.VITE_API_URL +
              "/assets/131d681e-228f-4969-82bc-21995e6bdbba?height=" +
              window.innerWidth
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
