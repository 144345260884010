import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PropTypes from "prop-types";
import "../css/calendar.css";
import { Box } from "@mui/system";
import deLocale from "@fullcalendar/core/locales/de";

const Calendar = (props) => {
  const convertToFullcalendarDateFormat = (date) => {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  };

  const addMonths = (date, months) => {
    let d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  };

  const getAllEvents = async () => {
    let fullcalendarEvents = [];
    let dataCopy = JSON.parse(JSON.stringify(props.data));
    for (let i = 0; i < dataCopy.length; i++) {
      const event = dataCopy[i];
      let date = new Date(event.start);
      event.end = new Date(event.end);
      let whileEnd = (event.status !== "active-terminated" && event.status !== "inactive") ? addMonths(event.end, 48) : event.end;
      while (date <= whileEnd) {
        fullcalendarEvents.push({
          title: event.title,
          start: convertToFullcalendarDateFormat(date),
          backgroundColor: event.color,
          borderColor: event.color,
        });
        date = addMonths(date, event.duration);
      }
    }
    return fullcalendarEvents;
  };

  return (
    <Box
      className={"card"}
    >
      <FullCalendar
        initialView="dayGridMonth"
        initialDate={convertToFullcalendarDateFormat(new Date())}
        plugins={[dayGridPlugin, interactionPlugin]}
        events={getAllEvents}
        editable={false}
        showNonCurrentDates={false}
        headerToolbar={{
          left: "today",
          center: "title",
          right: "prev,next",
          // dayGridDay,dayGridWeek,dayGridMonth,
        }}
        height={"512px"}
        dayMaxEventRows={true}
        locale={deLocale}
      />
    </Box>
  );
};

Calendar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.shape({
        title: PropTypes.string,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        color: PropTypes.string,
      }),
    })
  ),
};

export default Calendar;
