import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar, TextField
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import PropTypes from "prop-types";
import { getCssValue, handleImageLoaded } from "../../../services/utils";
import { t } from "i18next";

function EmployeesList(props) {
  const [filterName, setFilterName] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState(props.employees);

  React.useEffect(() => {
    let filtered = props.employees.filter((employee) => {
      if (
        (props.filterDepartment === "" || props.filterDepartment === employee.department) &&
        (props.filterTeam === "" || props.filterTeam === employee.team) &&
        (props.filterTypeEmployment === "" || props.filterTypeEmployment === employee.type_of_employment) &&
        (filterName === "" ? true : (!!employee.firstname && employee.firstname.toLowerCase().includes(filterName.toLowerCase()) || (!!employee.lastname && employee.lastname.toLowerCase().includes(filterName.toLowerCase()))))) {
        return employee
      }
    });
    let isUserVisible = filtered.filter((user) => {
      return user.id === props.selectedUser
    })
    if (isUserVisible.length === 0 && !!filtered[0]) {
      props.onClick(filtered[0].id);
    }
    setFilteredEmployees(filtered);
  }, [props.filterTeam, props.filterDepartment, props.filterTypeEmployment, props.employees, filterName])

  return (
    <List
      disablePadding
      sx={{minHeight: "100%"}}
    >
      <TextField
        size={"small"}
        sx={{
          width: "calc(100% - (2 * var(--detailsBoxPadding)))",
          margin: "var(--detailsBoxPadding) var(--detailsBoxPadding) 8px var(--detailsBoxPadding)",
        }}
        label={t("search")}
        type={"text"}
        value={filterName}
        onChange={(event) => setFilterName(event.target.value)}
      />
      {!!filteredEmployees &&
        filteredEmployees.map(function (user, i) {
          if (!props.hideEmployees || !props.hideEmployees.includes(user.id)) {
            let firstname = "";
            if (user.firstname) {
              firstname = user.firstname + " ";
            }
            let lastname = "";
            if (user.lastname) {
              lastname = user.lastname + " ";
            }
            let name = firstname + lastname;
            return (
              <ListItem disablePadding
                        key={i}
                        sx={{
                          p: "var(--detailsBoxPadding)",
                          maxHeight: "64px"
                        }}
              >
                <ListItemButton
                  selected={props.selectedUser === user.id}
                  onClick={() => props.onClick(user.id)}
                >
                  <ListItemIcon sx={{minWidth: "50px"}}>
                    {user.avatar ? (
                      <Avatar
                        key={user.email}
                        alt={user.firstname + " " + user.lastname}
                        src={"assets/loading.svg"}
                        sx={{ height: '40px', width: '40px' }}
                        onLoad={(event) =>
                          handleImageLoaded(
                            event,
                            user.avatar.id,
                            "?width=" + getCssValue("--lineHeight")
                          )
                        }
                      />
                    ) : (
                      <AccountCircle sx={{ fontSize: 40 }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
    </List>
  );
}

EmployeesList.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.object),
  selectedUser: PropTypes.string,
  onClick: PropTypes.func,
  hideEmployees: PropTypes.arrayOf(PropTypes.string),
};

export default EmployeesList;
