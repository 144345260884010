import React from "react";
import { Grid } from "@mui/material";
import BarChart from "./barChart";
import PropTypes from "prop-types";
import { t } from "i18next";

const ChartCostsPerMonth = (props) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].dataset.data[context[0].dataIndex].title;
          },
          label: function (context) {
            return (
              context.dataset.data[
                context.dataIndex
              ].nested.value.toLocaleString() + " €"
            );
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          maxRotation: 0,
        },
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
        offset: true,
      },
    },
    parsing: {
      xAxisKey: "id",
      yAxisKey: "nested.value",
    },
  };

  return (
    <Grid container spacing={2} sx={{ pt: "var(--listsPadding)" }}>
      <Grid item xs={12} sm={6}>
        <BarChart
          title={t("dashboard_cloudBasedTools")}
          width={"100%"}
          options={options}
          data={props.cloudBased.sort((a, b) =>
            a.costs_per_month < b.costs_per_month ? 1 : -1
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BarChart
          title={t("dashboard_hostingCloud")}
          options={options}
          data={props.hosting.sort((a, b) =>
            a.costs_per_month < b.costs_per_month ? 1 : -1
          )}
        />
      </Grid>
    </Grid>
  );
};

ChartCostsPerMonth.propTypes = {
  cloudBased: PropTypes.arrayOf(PropTypes.shape({})),
  hosting: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.string,
};

export default ChartCostsPerMonth;
