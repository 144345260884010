import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar, TextField
} from "@mui/material";
import PropTypes from "prop-types";
import { Apps } from "@mui/icons-material";
import {getCssValue, handleImageLoaded} from "../../../services/utils";
import { t } from "i18next";

function ToolsList(props) {
  const [filterName, setFilterName] = useState("");

  React.useEffect(() => {
    let elements = document.getElementsByClassName("toolsLogo");
    for (let i = 0; i < elements.length; i++) {
      elements[i].src = "assets/loading.svg";
    }
  }, [props.tools]);

  return (
    <>
      <List disablePadding
            sx={{minHeight: "100%"}}
      >
        <TextField
          size={"small"}
          sx={{
            width: "calc(100% - (2 * var(--detailsBoxPadding)))",
            margin: "var(--detailsBoxPadding) var(--detailsBoxPadding) 8px var(--detailsBoxPadding)",
          }}
          label={t("search")}
          type={"text"}
          value={filterName}
          onChange={(event) => setFilterName(event.target.value)}
        />
        {!!props.tools &&
          props.tools.filter((tool) => {
            if (filterName === "" ? true : (tool.tool_synced.name.toLowerCase().includes(filterName.toLowerCase()))) {
              return tool
            }
          }).map(function (tool) {
            return (
              <ListItem disablePadding
                        key={tool.id}
                        sx={{
                          p: "var(--detailsBoxPadding)",
                          maxHeight: "64px"
                        }}
              >
                <ListItemButton
                  selected={props.selectedTool.id === tool.id}
                  onClick={() => props.onClick(tool)}
                >
                  <ListItemIcon sx={{minWidth: "50px"}}>
                  {tool.tool_synced.logo ? (
                    <Avatar
                      alt={tool.tool_synced.name}
                      src={"assets/loading.svg"}
                      sx={{ height: '40px', width: '40px' }}
                      onLoad={(event) =>
                        handleImageLoaded(
                          event,
                          tool.tool_synced.logo.id,
                          "?width=" + getCssValue("--lineHeight")
                        )
                      }
                    />
                    ) : (
                      <Apps sx={{ fontSize: 40 }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={tool.tool_synced.name}
                                secondary={(tool.duration_of_contract !== null ? t('duration_' + tool.duration_of_contract) : "") + (tool.costs_per_unit ? ", €" + tool.payment_amount : /*costs_per_unit ist leer*/ "")}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
    </>
  );
}

ToolsList.propTypes = {
  tools: PropTypes.arrayOf(PropTypes.object),
  selectedTool: PropTypes.object,
  onClick: PropTypes.func,
};

export default ToolsList;
