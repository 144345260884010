import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./authentication.css";
import { API_register } from "../../services/backendService";
import Notification from "../notification";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { t } from "i18next";

const Register = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const validateEmail = (email) => {
    console.log(email);
    return true;
    // todo
    // return String(email)
    //   .toLowerCase()
    //   .match(
    //     /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    //   );
  };

  const register = () => {
    if (email === null || validateEmail(email) === null) {
      setNotify({
        isOpen: true,
        message: t("toast_registerEmailError"),
        type: "error",
      });
    } else if (password === null) {
      setNotify({
        isOpen: true,
        message: t("toast_registerPasswordError"),
        type: "error",
      });
    } else if (!checked) {
      setNotify({
        isOpen: true,
        message: t("toast_checkPrivacyError"),
        type: "error",
      });
    } else {
      API_register(email, password, companyName).then((result) => {
        if (
          !!result.response &&
          !!result.response.data &&
          !!result.response.data.errors
        ) {
          for (let i = 0; i < result.response.data.errors.length; i++) {
            const resultElement = result.response.data.errors[i];
            if (resultElement.extensions.code === "RECORD_NOT_UNIQUE") {
              setNotify({
                isOpen: true,
                message: t("toast_emailNotUnique"),
                type: "error",
              });
            }
          }
        } else {
          setNotify({
            isOpen: true,
            message: t("register_accountCreated"),
            type: "success",
          });
        }
      });
    }
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Box id={"form"} sx={{ width: { xs: "100%", sm: "33%" }, backgroundColor: "white" }}>
          <Box className={"innerBox"} sx={{p: "var(--detailsBoxPadding)", maxWidth: "400"}}>
            <Typography sx={{fontSize: "1.8rem"}}>{t("register_header")}</Typography>
            <Typography sx={{ pb: "1.5rem", fontSize: "1rem" }}>
              {t("register_subheader")}
            </Typography>
            <FormControl>
              {(import.meta.env.VITE_CUSTOMER_NAME === "onboarding" || import.meta.env.VITE_CUSTOMER_NAME === "dev") && <TextField
                label={t("register_companyName")}
                variant={"outlined"}
                className={"textfield"}
                onChange={(e) => setCompanyName(e.target.value)}
              ></TextField>}
              <TextField
                label={t("email")}
                variant={"outlined"}
                className={"textfield"}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
              <TextField
                label={t("password")}
                variant={"outlined"}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              ></TextField>
              <Box className={"notes"} sx={{ whiteSpace: "normal" }}>
                <FormControlLabel
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  control={<Checkbox />}
                  label={<Typography sx={{fontSize: "0.9rem"}}>{t("register_privacy")}</Typography>}
                  sx={{fontSize: "0.9rem !important"}}
                />
              </Box>
              <Button
                variant={"contained"}
                onClick={register}
              >
                {t("register_button")}
              </Button>
              {import.meta.env.VITE_CUSTOMER_NAME !== "onboarding" && <Box className={"notes"}>
                <Typography variant={"body2"} sx={{ m: "0px 0.5rem 0px 0px" }}>
                  {t("register_alreadyHaveAccount")}
                </Typography>
                <Typography
                  variant={"body2"}
                  sx={{ cursor: "pointer", color: "primary.main" }}
                  onClick={() => navigate("/login")}
                >
                  {t("register_login")}
                </Typography>
              </Box>}
            </FormControl>
          </Box>
        </Box>
        <Box
          id={"image"}
          sx={{
            width: { xs: "0px", sm: "77%" },
            display: { xs: "none", sm: "auto" },
            backgroundColor: "var(--background)",
          }}
        >
          <Box sx={{textAlign: "center"}}>
            <Box
              component="img"
              sx={{
                maxWidth: "65%",
                maxHeight: "65%",
                objectFit: "cover",
                marginBottom: "3em"
              }}
              alt=""
              src={
                import.meta.env.VITE_API_URL +
                "/assets/0e240a91-f608-4f69-adf4-5ebeb3e2804e?height=100"
              }
            />
            <Typography variant={"h5"}>{t('login_imageHeader')}</Typography>
            <Box sx={{marginBottom: "3em"}}>{t('login_imageSubheader')}</Box>
            <Box
              component="img"
              sx={{
                maxWidth: "80%",
                maxHeight: "80%",
                objectFit: "cover",
              }}
              alt=""
              src={
                import.meta.env.VITE_API_URL +
                "/assets/c9136b29-3b5e-4239-8c40-60daac5a8272?height=" +
                window.innerWidth
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

Register.propTypes = {
  handleLogin: PropTypes.func,
  setUserData: PropTypes.func,
};

export default Register;
