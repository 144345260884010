import React from "react";
import {
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  PersonOutline,
  CreditCard,
  SecurityOutlined,
  DashboardOutlined,
  GridViewOutlined,
  SpeedOutlined,
  TableChart, ArrowBackIosNew, ArrowForwardIos, Password, WidgetsOutlined
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Box } from "@mui/system";

const Sidebar = (props) => {
  const navigate = useNavigate();
  let showText = useMediaQuery("(min-width:700px)");
  const getWidth = () => {
    if (showText) {
      // Text soll angezeigt werden
      if (props.size === "full") {
        // Icon & Text
        return "256px";
      } else {
        // Icon
        showText = false;
        return "64px";
      }
    } else {
      // kein Text anzeigen
      if (props.size === "full") {
        // Icon
        return "64px";
      } else {
        // ausblenden
        return "0";
      }
    }
  };

  const changeSidebarSize = () => {
    props.setSidebarSize("full");
    switch (props.size) {
      case "full":
        props.setSidebarSize("small");
        break;
      case "small":
        props.setSidebarSize("full");
        break;
      default:
    }
  };

  let list = [
    {
      linkTo: "dashboard",
      image: <DashboardOutlined />,
      text: "menu_dashboard",
    },
    {
      linkTo: "employeesItem",
      image: <PersonOutline />,
      text: "menu_employees",
    },
    {
      linkTo: "toolsandservices",
      image: <GridViewOutlined />,
      text: "menu_toolsAndServices",
    },
    {
      linkTo: "testingtools",
      image: <SpeedOutlined />,
      text: "menu_toolsTesting",
    },
    {
      linkTo: "employeestoolsmatrix",
      image: <TableChart />,
      text: "menu_userToolsMatrix",
    },
    {
      linkTo: "paymentprovider",
      image: <CreditCard />,
      text: "menu_paymentprovider",
    },
    {
      linkTo: "privacy",
      image: <SecurityOutlined />,
      text: "menu_privacy",
    },
    {
      linkTo: "password",
      image: <Password />,
      text: "menu_password",
    },
    {
      linkTo: "toolMarketplace",
      image: <WidgetsOutlined />,
      text: "menu_toolMarketplace",
      position: "bottom"
    },
    // {
    //   openUrl: "https://app.caralegal.eu/",
    //   image: <SecurityOutlined />,
    //   text: "menu_privacy",
    // },
    // {
    //   openUrl: "https://heylogin.app/login",
    //   image: <Password />,
    //   text: "menu_password",
    // },
  ];

  function getElement(item) {
    return <Box key={!!item.linkTo ? item.linkTo : item.openUrl} sx={{cursor: "pointer", height: "64px", display: "flex", alignItems: "center"}}
                onClick={() => !!item.linkTo ? navigate("/" + item.linkTo) : window.open(item.openUrl,'_blank')}>
      <Box sx={{
        backgroundColor: location.pathname.includes(item.linkTo) ? "primary.main" : "transparent",
        color: location.pathname.includes(item.linkTo) ? "primary.contrastText" : "black",
        display: "flex",
        alignItems: "center",
        height: "50px",
        width: "100%",
        borderRadius: "0 25px 25px 0",
        "&:hover": {
          backgroundColor: "#ecedf3ff",
          color: "black"
        },
      }}>
        <Box sx={{display: "flex", width: "64px", justifyContent: "center"}}>
          {item.image}
        </Box>
        {showText && <Typography>{t(item.text)}</Typography>}
      </Box>
    </Box>
  }

  return (
    <Box sx={{position: "relative", backgroundColor: "var(--background)"}}>
      <Box sx={{
        position: "absolute", right: "-14px", top: "49px",
        backgroundColor: "white", zIndex: "10", cursor: "pointer", boxShadow: "0px 1px 5px 2px grey",
        width: "25px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "15px"
      }}
           onClick={() => changeSidebarSize()}
      >
        {props.size === "full" ? <ArrowBackIosNew sx={{fontSize: "small"}} /> : <ArrowForwardIos sx={{fontSize: "small"}} />}
      </Box>
      <Box
        sx={{
          minWidth: getWidth(),
          maxWidth: getWidth(),
          overflowX: "hidden",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column"
        }}
      >
        <Box>
          {list.filter((item) => {return !item.position}).map((item) => {
            return (
              getElement(item)
            );
          })}
        </Box>
        <Box>
          {list.filter((item) => {return item.position === "bottom"}).map((item) => {
            return (
              getElement(item)
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

Sidebar.propTypes = {
  selectedMenu: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  setSidebarSize: PropTypes.func
};

export default Sidebar;
