import React, { useState } from "react";
import EmployeesList from "./components/employeesList";
import EmployeeDetails from "./components/employeeDetails";
import Notification from "../notification";
import PropTypes from "prop-types";
import {
  API_addEmployee,
  API_deleteEmployee,
  API_updateEmployee,
} from "../../services/backendService";
import EmployeesHeader from "./components/employeesHeader";
import EmployeeTools from "./components/employeeTools";
import { t } from "i18next";
import { Box } from "@mui/system";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";

const Employees = (props) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const [selectedEmployee, setSelectedEmployee] = React.useState(!!new URLSearchParams(useLocation().search).get('id') ? new URLSearchParams(useLocation().search).get('id') : !!props.employees[0] ? props.employees[0].id : null);
  const [showDetails, setShowDetails] = React.useState(true);

  const [filterDepartment, setFilterDepartment] = useState("");
  const [filterTeam, setFilterTeam] = useState("");
  const [filterTypeEmployment, setFilterTypeEmployment] = useState("");

  const handleListItemClick = (value) => {
    setShowDetails(true);
    setSelectedEmployee(value);
  };

  const handleArrowBackClick = () => {
    setShowDetails(false);
  };

  const addEmployee = () => {
    props.setLoading(true);
    API_addEmployee()
      .then(function (newEmployee) {
        let allNewEmployees = props.employees
          .concat(newEmployee)
          .sort((a, b) => {
            if (a.lastname === null) {
              return -1;
            }
            if (b.lastname === null) {
              return 1;
            }
            if (a.lastname === b.lastname) {
              return 0;
            }
            return a.lastname.localeCompare(b.lastname);
          });
        props.setEmployees(allNewEmployees);
        props.setLoading(false);
        setNotify({
          isOpen: true,
          message: t('toast_employee_add_success'),
          type: "success",
        });
        setSelectedEmployee(newEmployee.id);
      })
      .catch(function () {
        // handle error
        setNotify({
          isOpen: true,
          message: t('toast_employee_add_error'),
          type: "error",
        });
        props.setLoading(false);
      });
  };

  const updateEmployee = (object) => {
    props.setLoading(true);
    API_updateEmployee(selectedEmployee, object)
      .then(function (updatedEmployee) {
        let employeesCopy = JSON.parse(JSON.stringify(props.employees));
        let positionUpdatedUser;
        employeesCopy.forEach(function(employee, i) {
          if (employee.id === selectedEmployee) {
            positionUpdatedUser = i;
          }
        })
        employeesCopy
          .splice(positionUpdatedUser, 1, updatedEmployee);
        employeesCopy.sort((a, b) => {
            if (a.lastname === null) {
              return -1;
            }
            if (b.lastname === null) {
              return 1;
            }
            if (a.lastname === b.lastname) {
              return 0;
            }
            return a.lastname.localeCompare(b.lastname);
          });
        props.setEmployees(employeesCopy);
        setNotify({
          isOpen: true,
          message: t('toast_save_success'),
          type: "success",
        });
        props.setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setNotify({
          isOpen: true,
          message: t('toast_save_error'),
          type: "error",
        });
        props.setLoading(false);
      });
  };

  const deleteEmployee = () => {
    props.setLoading(true);
    API_deleteEmployee(selectedEmployee)
      .then(function () {
        let newAllEmployees = props.employees.filter(function (item) {
          return item.id !== selectedEmployee;
        });
        props.setEmployees(newAllEmployees);
        setSelectedEmployee(newAllEmployees[0].id)
        props.setLoading(false);
        setNotify({
          isOpen: true,
          message: t('toast_employee_delete_success'),
          type: "success",
        });
      })
      .catch(function (error) {
        console.log(error);
        setNotify({
          isOpen: true,
          message: t('toast_employee_delete_error'),
          type: "error",
        });
      });
  };

  return (
    // <Box sx={{ height: "100%" }}>
    <>
      <EmployeesHeader
        showDetails={showDetails}
        handleArrowBackClick={handleArrowBackClick}
        addEmployee={addEmployee}
        filterDepartment={filterDepartment}
        setFilterDepartment={setFilterDepartment}
        filterTeam={filterTeam}
        setFilterTeam={setFilterTeam}
        filterTypeEmployment={filterTypeEmployment}
        setFilterTypeEmployment={setFilterTypeEmployment}
      />
      <Box sx={{position: "relative", height: "calc(100% - var(--lineHeight))", pl: "8px"}}>
        <Box sx={{position: "absolute", width: { xs: "100%", sm: "20%" }, overflow: "scroll", top: 0, bottom: "var(--detailsBoxPadding)", left: "var(--detailsBoxPadding)"}} className={"hideScrollbar cardShadow"}>
          <Scrollbars>
            <EmployeesList
              employees={props.employees}
              selectedUser={selectedEmployee}
              onClick={(value) => handleListItemClick(value)}
              filterDepartment={filterDepartment}
              filterTeam={filterTeam}
              filterTypeEmployment={filterTypeEmployment}
            />
          </Scrollbars>
        </Box>
        <Box sx={{position: "absolute", width: { xs: "0%", sm: "calc(80% - var(--detailsBoxPadding))" }, overflow: "scroll", top: 0, bottom: 0, right: 0}} className={"hideScrollbar"}>
          {props.employees && props.employees.length > 0 && (
            <Scrollbars>
              <EmployeeDetails
                employee={props.employees.filter(function(employee) {
                  if (employee.id === selectedEmployee) {
                    return employee
                  }
                })[0]}
                update={updateEmployee}
                delete={deleteEmployee}
                setLoading={props.setLoading}
              />
              <EmployeeTools
                currentEmployeeId={selectedEmployee}
                activated={props.employees.filter(function(employee) {
                  if (employee.id === selectedEmployee) {
                    return employee
                  }
                })[0].tools_activated.sort((a, b) => a.tools_id.tool_synced.name.localeCompare(b.tools_id.tool_synced.name))}
                responsibility={
                  props.employees.filter(function(employee) {
                    if (employee.id === selectedEmployee) {
                      return employee
                    }
                  })[0].tools_responsibility.sort((a, b) => a.tools_id.tool_synced.name.localeCompare(b.tools_id.tool_synced.name))
                }
                tools={props.tools}
                setLoading={props.setLoading}
                setNotify={setNotify}
                setEmployees={props.setEmployees}
                setTools={props.setTools}
              />
            </Scrollbars>
          )}
        </Box>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

Employees.propTypes = {
  employees: PropTypes.array,
  setEmployees: PropTypes.func,
  tools: PropTypes.array,
  setLoading: PropTypes.func,
  setTools: PropTypes.func,
  syncedTools: PropTypes.array,
};

export default Employees;
