import React, { useState, useEffect, useReducer, useMemo } from "react";
import Sidebar from "./components/sidebar";
import Employees from "./components/employees";
import Header from "./components/header";
import { Box, LinearProgress } from "@mui/material";
import Login from "./components/authentication/login";
import Dashboard from "./components/dashboard";
import ToolsAndServices from "./components/toolsAndServices";
import PaymentProvider from "./components/paymentProvider";
import { API_getAllData, API_getSyncedTools, API_getUser, API_localization } from "./services/backendService";
import EmployeesToolsMatrix from "./components/employeesToolsMatrix/index";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Register from "./components/authentication/register";
import ForgotPassword from "./components/authentication/forgotPassword";
import i18next, { t } from "i18next";
import i18n from "i18next";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { getCustomTheme } from "./customers/default.theme";
import PasswordPrivacy from "./components/passwordPrivacy";
import ToolMarketplace from "./components/toolMarketplace"

function App() {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isLoggedin, setLoggedin] = useState(localStorage.getItem("loggedin"));
  const [tools, setTools] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [syncedTools, setsyncedTools] = useState(null);
  const [syncedPayment, setsyncedPayment] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [sidebarSize, setSidebarSize] = useState("small");
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useMemo(
    () =>
      createTheme(getCustomTheme(!!userData && !!userData.maincolor ? userData.maincolor : undefined))
    ,
    [userData]
  );

  useEffect(() => {
    if (!isLoggedin && location.pathname !== "/login" && location.pathname !== "/register" && location.pathname !== "/forgotPassword" && location.pathname !== "/toolMarketplace") {
      navigate("/login");
    }
    API_localization().then((r) => {
      if (r.data) {
        let en = {},
          de = {};
        for (let i = 0; i < r.data.settings.translation_strings.length; i++) {
          const rElement = r.data.settings.translation_strings[i];
          if (rElement.translations["en-US"]) {
            en[rElement.key] = rElement.translations["en-US"].replace(
              String.fromCharCode(160),
              " "
            );
          }
          if (rElement.translations["de-DE"]) {
            de[rElement.key] = rElement.translations["de-DE"].replace(
              String.fromCharCode(160),
              " "
            );
          }
        }
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("lang") !== "ID") {
          i18next.addResourceBundle("en-US", "translation", en);
          i18next.addResourceBundle("de-DE", "translation", de);
        }
        i18n.changeLanguage("de-DE");
        setLoadingScreen(false);
        if (isLoggedin) {
          API_getUser().then((r) => {
            if (!!r.data && r.data.users_me !== null) {
              setUserData(r.data.users_me);
              if (r.data.users_me.language !== "de-DE") {
                i18n.changeLanguage(r.data.users_me.language);
                forceUpdate();
              }
            } else {
              localStorage.clear();
              setLoggedin(null);
            }
          });
        } else {
          forceUpdate();
        }
      }
      if (isLoggedin) {
        updateAllData();
      } else {
        API_getSyncedTools().then(r => {
          setsyncedTools(r.tools_database_synced.sort((a, b) => a.name.localeCompare(b.name)));
        })
      }
    });

    const interval = setInterval(() => {
      if (isLoggedin) {
        setLoading(true);
        updateAllData();
      }
    }, 300000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

  }, [isLoggedin]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAllData = () => {
    setLoading(true);
    API_getUser().then(function (response) {
      setUserData(response.data.users_me);
      API_getAllData().then(function (response) {
        let toolsData = response.tools,
          employeesData = response.employees,
          syncedToolsData = response.tools_database_synced,
          syncedPaymentsData = response.payments_synced;
        setTools(toolsData.sort((a, b) => a.tool_synced.name.localeCompare(b.tool_synced.name)));
        setEmployees(employeesData.sort((a, b) => {
          if (a.lastname === null) {
            return -1
          }
          if (b.lastname === null) {
            return 1
          }
          if (a.lastname === b.lastname) {
            return 0
          }
          return a.lastname.localeCompare(b.lastname)
        }));
        setsyncedTools(syncedToolsData.sort((a, b) => a.name.localeCompare(b.name)));
        setsyncedPayment(syncedPaymentsData.sort((a, b) => a.name.localeCompare(b.name)));
        setLoading(false);
      });
    });
  }

  function startLogoutTimer() {
    const logoutTimer = setTimeout(function() {
      setLoggedin(null);
      localStorage.clear();
      navigate("/login");
      clearTimeout(logoutTimer);
    }, 600000);
  }
  useEffect(() => {
    if (localStorage.getItem("stayLoggedin") === "false") {
      startLogoutTimer();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <>
        <title>SaaS Management by mvolution</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </>
      {loadingScreen && <Box sx={{position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "white"}}></Box>}
      {isLoggedin && <Header updateAllData={updateAllData}
              setLoading={setLoading}
              isLoading={isLoading}
              userData={userData}
              setUserData={setUserData}
              isLoggedin={isLoggedin}
              setLoggedin={setLoggedin}
              forceUpdate={forceUpdate}
              setSidebarSize={setSidebarSize} /> }
      {isLoading && isLoggedin && <LinearProgress color="primary" sx={{ zIndex: 2 }} />}
      {!loadingScreen && <Box
        sx={{
          display: "flex",
          position: "fixed",
          top: isLoggedin? "var(--lineHeight)" : 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        {isLoggedin && <Sidebar
          setSidebarSize={setSidebarSize}
          size={sidebarSize}
        />}
        <Box
          sx={{
            backgroundColor: "var(--background)",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%"
          }}
        >
          <Routes>
            <Route
              path="/login"
              element={
                <Login handleLogin={setLoggedin} setUserData={setUserData} forceUpdate={forceUpdate} startLogoutTimer={startLogoutTimer} />
              }
            />
            <Route
              path="/register"
              element={
                <Register
                  handleLogin={setLoggedin}
                  setUserData={setUserData}
                />
              }
            />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            {isLoggedin ?  <Route path="/"
                                  element={
                                    <Dashboard tools={tools} />}
            /> : <Route
              path="/"
              element={
                <Login handleLogin={setLoggedin} setUserData={setUserData} forceUpdate={forceUpdate} />
              }
            />}
            {isLoggedin && <Route path="/dashboard"
                                  element={
              <Dashboard tools={tools} />}
            />}
            {isLoggedin && !!employees && <Route path="/employeesItem"
              element={<Employees
                        employees={employees}
                        setEmployees={setEmployees}
                        tools={tools}
                        setLoading={setLoading}
                        setTools={setTools}
                        syncedTools={syncedTools}
              />} />}
            {isLoggedin && !!tools && <Route path="/toolsandservices"
                                  element={<ToolsAndServices
                                    tools={tools.filter(function (tool) {
                                      if (!!tool && tool.status === null || !!tool && tool.status !== "inTesting") {
                                        return tool;
                                      }
                                    })}
                                    allTools={tools}
                                    setTools={setTools}
                                    employees={employees}
                                    syncedTools={syncedTools}
                                    syncedPayment={syncedPayment}
                                    setsyncedPayment={setsyncedPayment}
                                    setLoading={setLoading}
                                    setEmployees={setEmployees}
                                    header={t('tools_header')}
                                    privacyTools={tools.filter(function (tool) {
                                      if (!!tool && !!tool.tool_synced.subcategory && tool.tool_synced.subcategory.id === "b97d9f92-17c1-4cfe-bcac-5ec930efe106") {
                                        return tool;
                                      }
                                    })}
                                  />} />}
            {isLoggedin && !!tools && <Route path="/testingtools"
                                  element={<ToolsAndServices
                                    tools={tools.filter(function (tool) {
                                      if (tool.status === "inTesting") {
                                        return tool;
                                      }
                                    })}
                                    allTools={tools}
                                    setTools={setTools}
                                    employees={employees}
                                    syncedTools={syncedTools}
                                    syncedPayment={syncedPayment}
                                    setLoading={setLoading}
                                    setEmployees={setEmployees}
                                    setNewStatus={"inTesting"}
                                    header={t('toolsTesting_header')}
                                    setsyncedPayment={setsyncedPayment}
                                    privacyTools={tools.filter(function (tool) {
                                      if (!!tool && !!tool.tool_synced.subcategory && tool.tool_synced.subcategory.id === "b97d9f92-17c1-4cfe-bcac-5ec930efe106") {
                                        return tool;
                                      }
                                    })}
                                  />} />}
            {isLoggedin && !!employees && !!tools &&  <Route path="/employeestoolsmatrix"
                                             element={<EmployeesToolsMatrix
                                               employees={employees}
                                               tools={tools}
                                               setLoading={setLoading}
                                               setTools={setTools}
                                               setEmployees={setEmployees}
                                             />} />}
            {isLoggedin && !!syncedPayment && <Route path="/paymentprovider"
                                                             element={<PaymentProvider
                                                               payments={syncedPayment.filter(function (item) {
                                                                 if (item.tools.length > 0) return item
                                                               })}
                                                             />} />}
            {isLoggedin && !!syncedTools && <Route path="/password"
                                                     element={<PasswordPrivacy
                                                       tools={tools}
                                                       data={syncedTools.filter(function (item) {
                                                          if (!!item.subcategory && item.subcategory.id === "26b48949-05fc-4b6c-8a7e-de16b5db9664") return item
                                                       })}
                                                       header={"passwordPrivacy_passwordHeader"}
                                                       setLoading={setLoading}
                                                       setTools={setTools}
                                                       isLoggedin={isLoggedin}
                                                     />} />}
            {isLoggedin && !!syncedTools && <Route path="/privacy"
                                                   element={<PasswordPrivacy
                                                     tools={tools}
                                                     data={syncedTools.filter(function (item) {
                                                       if (!!item.subcategory && item.subcategory.id === "b97d9f92-17c1-4cfe-bcac-5ec930efe106") return item
                                                     })}
                                                     header={"passwordPrivacy_privacyHeader"}
                                                     setLoading={setLoading}
                                                     setTools={setTools}
                                                     isLoggedin={isLoggedin}
                                                   />} />}
            {!!syncedTools && <Route path="/toolMarketplace"
                                                   element={<ToolMarketplace
                                                     data={syncedTools.map((tool) => ({
                                                       category: !!tool.category ? tool.category.translations[0].name : "",
                                                       categoryId: tool.category.id,
                                                       subcategory: !!tool.subcategory ? tool.subcategory.translations[0].name : "",
                                                       subcategoryId: !!tool.subcategory ? tool.subcategory.id : ""
                                                     }))}
                                                     tools={tools}
                                                     allTools={syncedTools}
                                                     setTools={setTools}
                                                     setLoading={setLoading}
                                                     isLoggedin={isLoggedin}
                                                   />} />}
          </Routes>
        </Box>
      </Box>
      }
    </ThemeProvider>
  );
}

export default App;
