import React, { useState } from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox, Chip, Dialog, DialogTitle,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { Apps } from "@mui/icons-material";
import { getCssValue, handleImageLoaded, isColorBright } from "../../../services/utils";
import { de, enUS } from "date-fns/locale";
import i18n, { t } from "i18next";
import Notification from "../../notification";

function ToolDetails(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const [showDeleteRequest, setShowDeleteRequest] = useState(false);
  const [unitPrice, setUnitPrice] = useState(
    props.tool.costs_per_unit ? props.tool.costs_per_unit : 0
  );
  const [countInactiveUser, setCountInactiveUser] = useState(
    props.tool.countInactiveUser ? props.tool.countInactiveUser : 0
  );
  const [notes, setNotes] = useState(props.tool.notes ? props.tool.notes : "");
  const [dependingUser, setDependingUser] = React.useState(
    props.tool.depending_user ? props.tool.depending_user : false
  );

  React.useEffect(() => {
    if (document.getElementById("toolLogo")) {
      document.getElementById("toolLogo").src = "assets/loading.svg";
    }
    setUnitPrice(props.tool.costs_per_unit ? props.tool.costs_per_unit : 0);
    setCountInactiveUser(
      props.tool.countInactiveUser ? props.tool.countInactiveUser : 0
    );
    setNotes(props.tool.notes ? props.tool.notes : "");
    setDependingUser(
      props.tool.depending_user ? props.tool.depending_user : false
    );
  }, [props.tool]);

  const getStatusBackgroundColor = () => {
    switch (props.tool.status) {
      case "active-undiscontinued":
        return "#04a24c";
      case "active-terminated":
        return "#E21C3D";
      case "Paused":
        return "#e0e0e0";
      case "inTesting":
        return "#ff5963";
      case "inactive":
        return "#e21c3d";
    }
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      {showDeleteRequest && (
        <Dialog open={showDeleteRequest}>
          <DialogTitle>
            Soll das Tool {props.tool.tool_synced.name} wirklich
            gelöscht werden?
          </DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                props.remove();
                setShowDeleteRequest(false);
              }}
            >
              ja
            </Button>
            <Button onClick={() => setShowDeleteRequest(false)}>nein</Button>
          </Box>
        </Dialog>
      )}
      {props.tool !== undefined && (
        <Box className={"card"}
             id={"top"}
             sx={{
               m: "0 var(--detailsBoxPadding)",
               p: "var(--detailsBoxPadding) var(--detailsBoxPadding) 0 0",
             }}
        >
          <Box id={"detailsTopBox"}></Box>
          <Box sx={{pl: "var(--detailsBoxPadding)", mb: "var(--detailsBoxPadding)", display: "flex"}}>
            <Box>
              <Typography sx={{maxWidth: "219px", minWidth: "219px"}} noWrap variant={"h5"}>{props.tool.tool_synced.name}</Typography>
            </Box>
            <Chip sx={{ml: "13px"}} label={props.tool.tool_synced.category.translations[0].name} variant="outlined" />
            {props.tool.tool_synced.subcategory && <Chip label={props.tool.tool_synced.subcategory.translations[0].name} variant="outlined" sx={{ml: "var(--detailsBoxPadding)"}}/>}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                p: "0 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
              }}
              className={"gridElement"}
            >
              {/*Logo*/}
              {props.tool.tool_synced.logo ? (
                <Box
                  id={"toolLogo"}
                  component="img"
                  sx={{
                    height:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    width:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    borderRadius: "6px",
                  }}
                  alt={props.tool.tool_synced.name}
                  src={"assets/loading.svg"}
                  onLoad={(event) =>
                    handleImageLoaded(
                      event,
                      props.tool.tool_synced.logo.id,
                      "?width=" +
                        (getCssValue("--detailsBoxWidth") -
                          getCssValue("--detailsBoxPadding"))
                    )
                  }
                />
              ) : (
                <Apps sx={{ fontSize: "var(--detailsBoxWidth)" }} />
              )}
            </Box>
            <Box
              sx={{
                width: "var(--detailsBoxWidth)",
                p: "0 0 0 var(--detailsBoxPadding)",
              }}
              className={"gridElement"}
            >

              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                }}
                label={t("toolsServices_price")}
                type={"tel"}
                value={unitPrice.toLocaleString()}
                onChange={(event) => setUnitPrice(event.target.value)}
                onBlur={(event) => {
                  if (!isNaN(event.target.value.toString().replace(",", "."))) {
                    props.update(
                      "{ costs_per_unit: " + Number(event.target.value.toString().replace(",", ".")) + " }"
                    )
                  } else {
                    props.update("error", "toast_mustBeNumber")
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                  m: "var(--detailsBoxPadding) 0 0 0",
                }}
                label={t("toolsServices_costsPerMonth")}
                type={"tel"}
                value={
                  props.tool.duration_of_contract === "unknown" ||
                  props.tool.duration_of_contract === "onetime"
                    ? t('toolsServices_seeContract')
                    : "€ " + props.tool.costs_per_month !== "null" &&
                    props.tool.costs_per_month != null
                      ? props.tool.costs_per_month.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                      : "-"
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                disabled
              />
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                  m: "var(--detailsBoxPadding) 0 0 0",
                }}
                label={t("toolsServices_costsPerYear")}
                type={"tel"}
                value={
                  props.tool.duration_of_contract === "unknown" ||
                  props.tool.duration_of_contract === "onetime"
                    ? t('toolsServices_seeContract')
                    : "€ " + props.tool.costs_per_year !== "null" &&
                    props.tool.costs_per_year != null
                      ? props.tool.costs_per_year.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                      : "-"
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                disabled
              />
            </Box>
            <Box
              sx={{
                width: "var(--detailsBoxWidth)",
                p: "0 0 0 var(--detailsBoxPadding)",
              }}
              className={"gridElement"}
            >
              <Box sx={{
                width: "var(--detailsBoxWidth)",
              }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "de-DE" ? de : enUS}>
                  <DatePicker
                    sx={{mt: "var(--detailsBoxPadding)"}}
                    label={t("toolsServices_contractStart")}
                    value={props.tool.start}
                    onChange={(newValue) => {
                      if (newValue !== "Invalid Date") {
                        if (props.tool.status !== "active-terminated" && props.tool.status !== "inactive") {
                          props.update(
                            '{start: "' + format(newValue, "yyyy-MM-dd") + '"}'
                          )
                        } else {
                          if (new Date(newValue) <= new Date(props.tool.terminated_at)) {
                            props.update(
                              '{start: "' + format(newValue, "yyyy-MM-dd") + '"}'
                            )
                          } else {
                            setNotify({
                              isOpen: true,
                              message: t('toast_contractEndNotAfterStart'),
                              type: "error",
                            });
                          }
                        }
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/*<LocalizationProvider*/}
                {/*  dateAdapter={AdapterDateFns}*/}
                {/*  adapterLocale={i18n.language === "de-DE" ? de : enUS}*/}
                {/*>*/}
                {/*  <MobileDatePicker*/}
                {/*    label={t("toolsServices_contractStart")}*/}
                {/*    value={props.tool.start}*/}
                {/*    open={open}*/}
                {/*    onOpen={() => setOpen(true)}*/}
                {/*    onClose={() => setOpen(false)}*/}
                {/*    onChange={(newValue) =>*/}
                {/*      props.update(*/}
                {/*        '{start: "' + format(newValue, "yyyy-MM-dd") + '"}'*/}
                {/*      )*/}
                {/*    }*/}
                {/*    inputFormat={"dd.MM.yyyy"}*/}
                {/*    showToolbar={false}*/}
                {/*    componentsProps={{*/}
                {/*      actionBar: {*/}
                {/*        // The actions will be the same between desktop and mobile*/}
                {/*        actions: [],*/}
                {/*      },*/}
                {/*    }}*/}
                {/*    renderInput={(params) => <TextField {...params} />}*/}
                {/*    sx={{*/}
                {/*      "& .MuiOutlinedInput-root": {*/}
                {/*        "& > fieldset": { borderColor: "transparent !important" },*/}
                {/*      },*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</LocalizationProvider>*/}
              </Box>
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                  m: "var(--detailsBoxPadding) 0 0 0",
                }}
                label={t("toolsServices_contractDuration")}
                type={"tel"}
                value={
                  props.tool.duration_of_contract !== null
                    ? props.tool.duration_of_contract
                    : "empty"
                }
                onChange={(event) => {
                  props.update(
                    '{ duration_of_contract: "' + event.target.value + '"}'
                  );
                }}
                select
              >
                <MenuItem value={"monthly"}>{t("duration_monthly")}</MenuItem>
                <MenuItem value={"oneYear"}>{t("duration_oneYear")}</MenuItem>
                <MenuItem value={"twoYears"}>{t("duration_twoYears")}</MenuItem>
                <MenuItem value={"unknown"}>{t("duration_unknown")}</MenuItem>
                <MenuItem value={"onetime"}>{t("duration_onetime")}</MenuItem>
                <MenuItem
                  value={"empty"}
                  sx={{ display: "none" }}
                  disabled={true}
                >
                  {t('select')}
                </MenuItem>
              </TextField>
              {props.tool.status === "active-terminated" ||
              props.tool.status === "inactive" ? (
                <Box sx={{mt: "var(--detailsBoxPadding)"}}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "de-DE" ? de : enUS}>
                    <DatePicker
                      sx={{mt: "var(--detailsBoxPadding)"}}
                      label={t("toolsServices_contractEnd")}
                      value={props.tool.terminated_at ? props.tool.terminated_at : null}
                      onChange={(newValue) => {
                        if (newValue !== "Invalid Date") {
                          if (new Date(props.tool.start) <= new Date(newValue)) {
                            props.update(
                              '{terminated_at: "' + format(newValue, "yyyy-MM-dd") + '"}'
                            )
                          } else {
                            setNotify({
                              isOpen: true,
                              message: t('toast_contractEndNotAfterStart'),
                              type: "error",
                            });
                          }
                        }
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              ) : (
                <TextField
                  sx={{
                    width: "var(--detailsBoxWidth)",
                    m: "var(--detailsBoxPadding) 0 0 0",
                  }}
                  label={t("toolsServices_contractEnd")}
                  type={"string"}
                  value={
                    props.tool.end === null || props.tool.duration_of_contract === "unknown" ||
                    props.tool.duration_of_contract === "onetime"
                      ? t('toolsServices_seeContract')
                      : format(new Date(props.tool.end), "dd.MM.yyyy")
                  }
                  disabled
                />
              )}
            </Box>
            <Box
              sx={{
                width: "var(--detailsBoxWidth)",
                p: "0 0 0 var(--detailsBoxPadding)",
              }}
              className={"gridElement"}
            >
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                }}
                label={t("toolsServices_activatedUserCount")}
                type={"string"}
                value={
                  props.tool.employees_activated
                    ? props.tool.employees_activated.length
                    : 0
                }
                disabled
              />
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                  m: "var(--detailsBoxPadding) 0 0 0",
                }}
                label={t("toolsServices_inactiveUser")}
                type={"tel"}
                value={countInactiveUser.toLocaleString()}
                onChange={(event) => setCountInactiveUser(event.target.value)}
                onBlur={(event) =>
                  props.update(
                    "{ countInactiveUser: " +
                    parseInt(event.target.value) +
                    " }"
                  )
                }
              />
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                  m: "var(--detailsBoxPadding) 0 0 0",
                }}
                label={t("toolsServices_dependingUser")}
                type={"string"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Checkbox
                        className={"checkbox"}
                        checked={dependingUser}
                        value={true}
                        onChange={() => {
                          const newValue = !props.tool.depending_user;
                          setDependingUser(newValue);
                          props.update("{ depending_user: " + newValue + " }");
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                width: "var(--detailsBoxWidth)",
                p: "0 0 0 var(--detailsBoxPadding)",
              }}
              className={"gridElement"}
            >
              {/*<TextField*/}
              {/*  sx={{*/}
              {/*    width: "var(--detailsBoxWidth)",*/}
              {/*    m: "var(--detailsBoxPadding) 0 0 0",*/}
              {/*  }}*/}
              {/*  label={t("toolsServices_category")}*/}
              {/*  type={"string"}*/}
              {/*  value={props.tool.tool_synced.category.name}*/}
              {/*  disabled*/}
              {/*/>*/}
              {/*<TextField*/}
              {/*  sx={{*/}
              {/*    width: "var(--detailsBoxWidth)",*/}
              {/*    m: "var(--detailsBoxPadding) 0 0 0",*/}
              {/*  }}*/}
              {/*  label={t("toolsServices_subcategory")}*/}
              {/*  type={"string"}*/}
              {/*  value={*/}
              {/*    !!props.tool.tool_synced.subcategory && props.tool.tool_synced.subcategory.name*/}
              {/*      ? props.tool.tool_synced.subcategory.name*/}
              {/*      : "-"*/}
              {/*  }*/}
              {/*  disabled*/}
              {/*/>*/}
              <TextField
                sx={{
                  width: "var(--detailsBoxWidth)",
                }}
                label={t("toolsServices_type")}
                type={"string"}
                value={
                  props.tool.type_of_tool !== null
                    ? props.tool.type_of_tool
                    : "empty"
                }
                onChange={(event) => {
                  props.update(
                    '{ type_of_tool: "' + event.target.value + '" }'
                  );
                }}
                select
              >
                <MenuItem value={"Cloud based"}>{t('toolsServices_cloudBased')}</MenuItem>
                <MenuItem value={"On-Premise"}>{t('toolsServices_onPremise')}</MenuItem>
                <MenuItem value={"Hosting / Cloud Provider"}>{t('toolsServices_hostingCloudProvider')}</MenuItem>
                <MenuItem
                  value={"empty"}
                  sx={{ display: "none" }}
                  disabled={true}
                >
                  {t('select')}
                </MenuItem>
              </TextField>
            </Box>
            <Box sx={{display: "flex"}}>
              <Box sx={{display: "flex", flexDirection: "column", width: "var(--detailsBoxWidth)"}}>
                {/*Status*/}
                <TextField
                  sx={{
                    color: isColorBright(getStatusBackgroundColor()) ? "black" : "white",
                    borderRadius: "var(--listsPadding)",
                    textAlign: "center",
                    m: "0 0 0 var(--detailsBoxPadding)",
                    width: "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: getStatusBackgroundColor(),
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: getStatusBackgroundColor(),
                      borderRadius: "var(--listsPadding)",
                      color: isColorBright(getStatusBackgroundColor()) ? "black" : "white"
                    },
                    "& .MuiSelect-select": {
                      p: "7px !important",
                    },
                    "& .MuiSvgIcon-root": {
                      display: "none",
                    },
                    ...(props.tool.status === undefined && {
                      "& .MuiInputLabel-root": { color: "red" },
                    }),
                  }}
                  value={props.tool.status !== null ? props.tool.status : "empty"}
                  onChange={(event) => {
                    props.tool.status = event.target.value;
                    props.update('{ status: "' + event.target.value + '" }');
                  }}
                  select // tell TextField to render select
                >
                  <MenuItem value={"active-undiscontinued"}>{t('toolsServices_activeUndiscontinued')}</MenuItem>
                  <MenuItem value={"active-terminated"}>{t('toolsServices_activeTerminated')}</MenuItem>
                  <MenuItem value={"Paused"}>{t('toolsServices_paused')}</MenuItem>
                  <MenuItem value={"inTesting"}>{t('toolsServices_inTest')}</MenuItem>
                  <MenuItem value={"inactive"}>{t('toolsServices_inactiveTerminated')}</MenuItem>
                  <MenuItem
                    value={"empty"}
                    sx={{ display: "none" }}
                    disabled={true}
                  >
                    {t('select')}
                  </MenuItem>
                </TextField>
                <Button
                  sx={{
                    m: "var(--detailsBoxPadding) 0 0 var(--detailsBoxPadding)",
                    width: "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                  }}
                  variant={"outlined"}
                  onClick={() => setShowDeleteRequest(true)}
                >
                  {t("delete")}
                </Button>
              </Box>
              <Box
                className={"textareaWrapper"}
                sx={{ width: "calc(3 * var(--detailsBoxWidth) + 2 * var(--detailsBoxPadding))" }}
              >
                <textarea
                  placeholder={t('toolsServices_notes')}
                  rows="5"
                  value={notes}
                  onChange={(event) => setNotes(event.target.value)}
                  onBlur={(event) =>
                    props.update('{ notes: "' + event.target.value + '" }')
                  }
                ></textarea>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

ToolDetails.propTypes = {
  tool: PropTypes.shape({
    costs_per_unit: PropTypes.number,
    notes: PropTypes.string,
    costs_per_month: PropTypes.number,
    costs_per_year: PropTypes.number,
    duration_of_contract: PropTypes.string,
    employees_activated: PropTypes.array,
    countInactiveUser: PropTypes.number,
    type_of_tool: PropTypes.string,
    status: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    terminated_at: PropTypes.string,
    depending_user: PropTypes.bool,
    tool_synced: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape({
        id: PropTypes.string,
      }),
      category: PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string,
      }),
      subcategory: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    id: PropTypes.string,
  }),
  update: PropTypes.func,
  showList: PropTypes.bool,
  remove: PropTypes.func,
};

export default ToolDetails;
