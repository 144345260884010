import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Person, Sync } from "@mui/icons-material";
import { handleImageLoaded } from "../services/utils.jsx";
import Profile from "./profile";

const Header = (props) => {
  const [showProfile, setShowProfile] = useState(false);

  return (
    <Box
      sx={{
        width: "calc(100% - 10)",
        fontSize: "25px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "space-between",
        height: "64px",
        p: "0 10px 0 0",
        backgroundColor: "var(--background)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "64px", textAlign: "center", cursor: "pointer" }}>
          <Box
            component="img"
            sx={{width: "45px"}}
            alt={"the workplace"}
            src={"assets/the-workplace.svg"}
          />
        </Box>
        <Typography variant={"h6"}>{import.meta.env.VITE_CUSTOMER_NAME}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            pr: "var(--detailsBoxPadding)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.isLoggedin ? <Sync sx={{mr: "var(--detailsBoxPadding)"}} onClick={() => props.updateAllData()} /> : ""}
          {props.isLoggedin && <Avatar onClick={() => setShowProfile(!showProfile)}>
            {!!props.userData && !!props.userData.avatar && props.userData.avatar.id ? (
              <Box
                id={"header_avatar"}
                sx={{ width: "40px", height: "40px" }}
                component="img"
                alt=""
                src={"assets/loading.svg"}
                onLoad={(event) =>
                  handleImageLoaded(event, props.userData.avatar.id, "?height=40")
                }
              />
            ) : (
              <Person />
            )}
          </Avatar>}
        </Box>
      </Box>
      {props.isLoggedin && !!props.userData && showProfile && <Profile
        setLoading={props.setLoading}
        isLoading={props.isLoading}
        data={props.userData}
        setUserData={props.setUserData}
        setShowProfile={setShowProfile}
        setLoggedin={props.setLoggedin}
      />}
    </Box>
  );
};

Header.propTypes = {
  updateAllData: PropTypes.func,
  avatar: PropTypes.string,
};

export default Header;
