import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { t } from "i18next";

function PaymentsHeader(props) {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box sx={{width: { xs: "100%", sm: "20%" }}}>
        <Typography
          variant={"h5"}
          sx={{
            lineHeight: "var(--lineHeight)",
            width: "calc(var(--listsWidth) - 2*var(--listsPadding))",
            p: "0 var(--listsPadding) 0 calc(2 * var(--listsPadding))",
          }}
        >
          {t("paymentProvider_header")}
        </Typography>
      </Box>
      <Box sx={{width: { xs: "0%", sm: "calc(80% - var(--detailsBoxPadding))" }}}>
        <Typography
          variant={"h5"}
          sx={{
            lineHeight: "var(--lineHeight)",
            width: "calc(var(--listsWidth) - 2*var(--listsPadding))",
            p: "0 var(--listsPadding) 0 calc(2 * var(--listsPadding))",
          }}
        >
          {props.name}
        </Typography>
      </Box>
    </Box>
  );
}

PaymentsHeader.propTypes = {
  minWidth600: PropTypes.bool,
  showDetails: PropTypes.bool,
  handleArrowBackClick: PropTypes.func,
  addEmployee: PropTypes.func,
  name: PropTypes.string,
};

export default PaymentsHeader;
