import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import "./App.css";
import "./default.css";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import "@fontsource/roboto";

let tracesSampleRate = (typeof import.meta.env.VITE_SENTRY_TRACING_SAMPLE_RATE == "undefined" || import.meta.env.VITE_SENTRY_TRACING_SAMPLE_RATE === null)
  ? 1.0
  : parseFloat(import.meta.env.VITE_SENTRY_TRACING_SAMPLE_RATE)

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: tracesSampleRate,
});

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
