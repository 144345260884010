import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { t } from "i18next";

const Tabs = (props) => {

  return (
    <Box sx={{display: "flex", ml: "235px", mb: "var(--listsPadding)"}}>
      <Button
        variant={props.activeTab === "all" ? "contained" : "outlined"}
        onClick={() => props.setActiveTab("all")}
      >
        {t('employeeToolsMatrix_allTools')}
      </Button>
      <Button
        sx={{ml: "var(--listsPadding)"}}
        variant={props.activeTab === "active" ? "contained" : "outlined"}
        onClick={() => props.setActiveTab("active")}
      >
        {t('employeeToolsMatrix_activeTools')}
      </Button>
      <Button
        sx={{ml: "var(--listsPadding)"}}
        variant={props.activeTab === "inTest" ? "contained" : "outlined"}
        onClick={() => props.setActiveTab("inTest")}
      >
        {t('employeeToolsMatrix_inTest')}
      </Button>
    </Box>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};

export default Tabs;