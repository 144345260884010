import React from "react";
import { Snackbar, Alert } from "@mui/material";

import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

export default function Notification(props) {
  const { notify, setNotify } = props;
  const handleClose = () => {
    setNotify({
      ...notify,
      isOpen: false,
    });
  };
  return (
    <StyledSnackbar
      open={notify.isOpen}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert severity={notify.type} onClose={handleClose}>
        {notify.message}
      </Alert>
    </StyledSnackbar>
  );
}

Notification.propTypes = {
  notify: PropTypes.shape({
    isOpen: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.any,
  }),
  setNotify: PropTypes.any,
};
