import axios from "axios";
import { format } from "date-fns";
import { sha256 } from "./utils";
import i18n, { t } from "i18next";

let userData = "id email first_name last_name company language avatar { id } maincolor";
let tools =
  'id costs_per_unit costs_per_month costs_per_year payment_amount payment_date duration_of_contract status type_of_tool start end terminated_at notes depending_user countInactiveUser tool_synced { id name logo { id } company url_login url_web category { id color translations(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {name languages_code} } subcategory { id translations(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {name languages_code} } } employees_activated { id employees_id { id firstname lastname avatar { id } } tools_id { id } } employees_responsibility { id employees_id { id firstname lastname avatar { id } } tools_id { id } } payment { id name logo { id } } payment_method invoice { id directus_files_id { id title type } }';
// board { id }
let employees =
  "id firstname lastname email avatar { id } status team position location department type_of_employment tools_activated { id tools_id { id tool_synced { id name logo { id } } } employees_id { id } } tools_responsibility { id tools_id { id tool_synced { id name logo { id } } } }";
let tools_database_synced =
  'id name company category { id color translations(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {name languages_code} } subcategory { id translations(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {name languages_code} } logo { id } url_login url_web url_review translations_shortDescription(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {shortDescription languages_code}';
let payments_synced =
  'id name logo { id } tools { tool_synced { id name logo { id } category { id color translations(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {name languages_code} } subcategory { translations(filter: { languages_code: { _eq: "' + (!!i18n.language ? i18n.language : "de-DE") + '" }}) {name languages_code} } } }';
let columns = "id name order";

let refreshMutex = false;

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  //async (config) => {
  (config) => {
    config.headers.Authorization =
      "Bearer " + localStorage.getItem("access_token");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalRequest._retry) {
        while (refreshMutex) {
          await new Promise(resolve => setTimeout(resolve, 10))
          // small optimization: if mutex now unlocked, refresh must be done shortly before, return original request
          if (!refreshMutex) {
            return api(originalRequest)
          }
        }
        refreshMutex = true;
        originalRequest._retry = true;
        try {
          await API_refreshToken().then((rs) => {
            localStorage.setItem("refresh_token", rs.data.refresh_token);
            localStorage.setItem("access_token", rs.data.access_token);
          });
          refreshMutex = false
          return api(originalRequest);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            refreshMutex = false;
            return Promise.reject(_error.response.data);
          }

          refreshMutex = false;
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        refreshMutex = false;
        return Promise.reject(err.response.data);
      }
    }

    refreshMutex = false;
    return Promise.reject(err);
  }
);

export async function API_localization() {
  return await axios
    .create({
      baseURL: import.meta.env.VITE_API_URL,
    })
    .post("/graphql/system", {
      query: `{settings { translation_strings }
    }`,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}
export async function API_refreshToken() {
  return axios
    .create({
      baseURL: import.meta.env.VITE_API_URL,
    })
    .post("/auth/refresh", {
      refresh_token: localStorage.getItem("refresh_token"),
      mode: "json",
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}
export async function API_register(email, password, companyName) {
  return await axios
    .create({
      baseURL: import.meta.env.VITE_API_URL,
    })
    .post("/users", {
      email: email,
      password: sha256(password),
      role: "05c6a485-9399-4e89-ad87-e0d1fe2f66cf",
      language: "de-DE",
      company: !!companyName ? companyName : import.meta.env.VITE_CUSTOMER_NAME,
      status: "draft",
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function API_login(email, password, setNotify) {
  return await axios
    .create({
      baseURL: import.meta.env.VITE_API_URL,
    })
    .post("/auth/login", {
      email: email,
      password: sha256(password),
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      setNotify({
        isOpen: true,
        message: t("toast_loginError"),
        type: "error",
      });
      // handle error
    });
}

export async function API_getUser() {
  return await api
    .post("/graphql/system", {
      query: `{users_me {
        ${userData}
    }
    }`,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
      // handle error
    });
}

export async function API_updateUserData(id, object) {
  return await api
    .post("/graphql/system", {
      query: `mutation{update_users_item(id: "${id}", data: ${object}) { ${userData} } }`,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_deleteAccount(id) {
  return await api
    .delete("/users/" + id)
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getAllData() {
  return await api
    .post("/graphql", {
      query: `{payments_synced(limit: -1) {
        ${payments_synced}
      },
      employees(limit: -1) {
        ${employees}
      },
      tools_database_synced(limit: -1) {
        ${tools_database_synced}
      },
      tools(limit: -1) {
        ${tools}
      },
    }`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getSyncedTools() {
  return await axios
    .create({
      baseURL: import.meta.env.VITE_API_URL,
    })
.post("/graphql", {
      query: `{tools_database_synced(limit: -1) {
  ${tools_database_synced}
}}`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getImage(id, size) {
  return await api
    .get("/assets/" + id + size, { responseType: "blob" })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getImageWidthoutCredentials(id, size) {
  return await axios
    .create({
      baseURL: import.meta.env.VITE_API_URL,
    })
    .get("/assets/" + id + size, { responseType: "blob" })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getTool(id) {
  return await api
    .post("/graphql", {
      query: `{tools_by_id(id: "` + id + `"){ ${tools} }}`,
    })
    .then(function (response) {
      return response.data.data.tools_by_id;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_addTool(id, status) {
  if (status === undefined) {
    status = "active-undiscontinued";
  }
  let object =
    '{tool_synced: "' +
    id +
    '",' +
    "depending_user: false," +
    'status: "' +
    status +
    '",' +
    'start: "' +
    format(new Date(), "yyyy-MM-dd") +
    '"' +
    "}";
  return await api
    .post("/graphql", {
      query: `mutation
      {create_tools_item(data: ${object})
      { ${tools} } }`,
    })
    .then(function (response) {
      return response.data.data.create_tools_item;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function API_updateTool(id, object) {
  return await api
    .post("/graphql", {
      query:
        `mutation
      {update_tools_item(id: "` +
        id +
        `", data: ${object}) { ${tools} }}`,
    })
    .then(function (response) {
      return response.data.data.update_tools_item;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function API_addFileToTool(toolId, fileId, name) {
  return await api
    .post("/graphql", {
      query:
        `mutation {
          create_tools_files_item(data: {
            tools_id:
              {id: "` +
        toolId +
        `"},
            directus_files_id: {
              id: "` +
        fileId +
        `", storage: "s3", filename_download: "` +
        name +
        `"
            }
          })
          {id}
        }`,
    })
    .then(function (response) {
      return response.data.data;
    });
}

export async function API_removeToolFileConnection(id) {
  api.delete("/items/tools_files/" + id).then(function () {});
}

export async function API_removeFile(id) {
  api.delete("/files/" + id).then(function () {});
}

export async function API_removeTool(id) {
  api.delete("/items/tools/" + id).then(function () {});
}

export async function API_getSyncedToolsData(offset) {
  return await api
    .get("Tools%20Database%20synced", {
      params: { offset: offset ? offset : 0 },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getEmployeesAndTools() {
  return await api
    .post("/graphql", {
      query: `{
    employees(limit: -1) {
        ${employees}
    },
    tools(limit: -1) { ${tools} },
      ,}`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_addEmployee() {
  let object = '{status: "Active"}';
  return await api
    .post("/graphql", {
      query: `mutation
      {create_employees_item(data: ${object})
      { ${employees} } }`,
    })
    .then(function (response) {
      return response.data.data.create_employees_item;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function API_updateEmployee(id, object) {
  return await api
    .post("/graphql", {
      query:
        `mutation
      {update_employees_item(id: "` +
        id +
        `", data: ${object}) { ${employees} }}`,
    })
    .then(function (response) {
      return response.data.data.update_employees_item;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function API_getKanbanData(toolId) {
  return await api
    .post("/graphql", {
      query: `{
        boards(filter: { tool: { id: { _eq: "` +
        toolId +
        `" } } })
          {
            id
          }
        columns(filter: { board: { tool: { id: { _eq: "` +
        toolId +
        `" } } } })
          {
            id
            name
            order
          }
        swimlanes(filter: {board: {tool: {id: {_eq: "` +
    toolId +
    `" } } } })
          {
            id
            name
            order
          }
        tasks(filter: {board: {tool: {id: {_eq: "` +
        toolId +
    `"}}}})
          {
            id
            name
            note
            order
            column {
                id
            }
            swimlane {
                id
            }
          }
        }`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function API_deleteEmployee(id) {
  return await api.delete("/items/employees/" + id).then(function (response) {
    return response;
  });
}

export async function API_addEmployeeToolActivated(employeeId, toolId) {
  return await api
    .post("items/employees_tools_activated", {
      employees_id: employeeId,
      tools_id: toolId,
    })
    .then(function (response) {
      return response.data.data;
    });
}

export async function API_removeEmployeeToolActivated(id) {
  return await api
    .delete("items/employees_tools_activated/" + id)
    .then(function (response) {
      return response.data.data;
    });
}

export async function API_addEmployeeToolResponsibility(employeeId, toolId) {
  return await api
    .post("items/employees_tools_responsibility", {
      employees_id: employeeId,
      tools_id: toolId,
    })
    .then(function (response) {
      return response.data.data;
    });
}

export async function API_removeEmployeeToolResponsibility(id) {
  return await api
    .delete("items/employees_tools_responsibility/" + id)
    .then(function (response) {
      return response.data.data;
    });
}

export async function API_customerAddNewTool(website) {
  return await api
    .post("https://api.saas.the-workplace.app/flows/trigger/73861065-72b2-465d-ab81-20bf990fd59a",
      {newTool: website, customerWebsite: import.meta.env.CUSTOMER}
    )
    .then(function (response) {
      return response;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getSyncedPayment() {
  return await api
    .post("/graphql", {
      query: `{payments_synced(limit: -1) {
        ${payments_synced}
    },
    }`,
    })
    .then(function (response) {
      return response.data.data.payments_synced;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_uploadFile(file, folder) {
  let formData = new FormData();
  formData.append("folder", folder);
  formData.append("file", file);
  formData.append("title", file.name);
  return await api
    .post(import.meta.env.VITE_API_URL + "/files", formData)
    .then(function (response) {
      return response.data.data.id;
    })
    .catch(function (response) {
      console.log("error", response);
      // handle error
    });
}

export async function API_uploadPhoto(file, folder) {
  let formData = new FormData();
  formData.append("folder", folder);
  formData.append("title", file.name);
  formData.append("file", file);
  return await api
    .post("/files", formData)
    .then(function (response) {
      return response.data.data.id;
    })
    .catch(function (response) {
      console.log("error", response);
      // handle error
    });
}

export async function API_getBoards(toolId) {
  return await api
    .post("/graphql", {
      query: `{
        columns(filter: { tool: { id: { _eq: "` + toolId + `}}}) ${columns}},`,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getColumns() {
  return await api
    .post("/graphql", {
      query: `{
        columns(limit: -1) {
            ${columns}
        },`,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}
export async function API_getSwimlanes(offset) {
  return await api
    .get("swimlanes", {
      params: { offset: offset ? offset : 0 },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_getTasks(offset) {
  return await api
    .get("tasks", {
      params: { offset: offset ? offset : 0 },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      // handle error
    });
}

export async function API_updateTask(data, id) {
  return await api
    .patch("items/tasks/" + id, data)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      // handle error
    });
}

export async function API_updateSwimlane(data, id) {
  return await api
    .patch("items/swimlanes/" + id, data)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      // handle error
    });
}

export async function API_updateColumn(data, id) {
  return await api
    .patch("items/columns/" + id, data)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      // handle error
    });
}

export async function API_addBoard(toolId) {
  return await api
    .post("/flows/trigger/2e498e44-e8a2-42e4-bb62-01982ecdf3b8", {lang: i18n.language, toolId: toolId})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      // handle error
    });
}

export async function API_addColumn(data) {
  return await api
    .post("/graphql", {
      query: `mutation
      { create_columns_item(data: ${data}) { id }
      }`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      // handle error
    });
}

export async function API_addSwimlane(data) {
  return await api
    .post("/graphql", {
      query: `mutation
      {create_swimlanes_item(data: ${data}) { id }
      }`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      // handle error
    });
}

export async function API_addTask(array) {
  return await api
    .post("/graphql", {
      query: `mutation
      {create_tasks_item(data: ${array})
      { id }}`,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function API_deleteBoards(records) {
  return await api
    .delete("boards", { params: { records: records } })
    .then(function (response) {
      return response.data.records;
    });
}

export async function API_deleteTasks(records) {
  return await api
    .delete("items/tasks", { data: records })
    .then(function (response) {
      return response;
    });
}

export async function API_deleteColumns(records) {
  return await api
    .delete("items/columns", { data: records })
    .then(function (response) {
      return response;
    });
}

export async function API_deleteSwimlanes(records) {
  return await api
    .delete("items/swimlanes", { data: records })
    .then(function (response) {
      return response;
    });
}

export async function API_uploadHeylogin(data) {
  return await api
      .post(import.meta.env.VITE_API_URL + "/flows/trigger/93d661e9-8715-47b2-bef0-633fbd90f467", {data: data})
      .then(function (response) {
        console.log(response)
      })
      .catch(function (response) {
        console.log("error", response);
        // handle error
      });
}
