import React from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";
import { TreeItem, TreeView } from "@mui/lab";
import { Checkbox, Typography } from "@mui/material";
import "../css/list.css"
import { Box } from "@mui/system";

//BFS algorithm to find node by his ID
const bfsSearch = (graph, targetId) => {
  const queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode.id === targetId) {
      return currNode;
    }
    if (currNode.children) {
      queue.push(...currNode.children);
    }
  }
  return []; // Target node not found
};

function ToolMarketplaceList(props) {

  React.useEffect(() => {
    if (!!props.data) {
      let activeNodes = [];
      for (let i = 0; i < props.data.length; i++) {
        let parentActive = false;
        const parent = props.data[i];
        if (parent.name.toLowerCase().indexOf(props.filterName.toLowerCase()) !== -1) {
          activeNodes.push(parent.id);
          parentActive = true;
        }
        for (let j = 0; j < parent.tools.length; j++) {
          const tool = parent.tools[j];
          if (tool.name.toLowerCase().indexOf(props.filterName.toLowerCase()) !== -1) {
            tool.visible = true;
            if (!parentActive) {
              activeNodes.push(parent.id);
            }
          } else {
            tool.visible = false;
          }
        }
        for (let j = 0; j < parent.children.length; j++) {
          let childrenActive = false;
          const child = parent.children[j];
          if (parentActive) {
            activeNodes.push(child.id);
            childrenActive = true;
          } else {
            if (child.name.toLowerCase().indexOf(props.filterName.toLowerCase()) !== -1) {
              activeNodes.push(child.id);
              childrenActive = true;
            }
          }
          for (let k = 0; k < child.tools.length; k++) {
            const tool = child.tools[k];
            if (parentActive || childrenActive) {
              tool.visible = true;
            } else {
              if (tool.name.toLowerCase().indexOf(props.filterName.toLowerCase()) !== -1) {
                tool.visible = true;
                if (!parentActive) {
                  activeNodes.push(parent.id);
                }
                if (!childrenActive) {
                  activeNodes.push(child.id);
                }
              } else {
                tool.visible = false;
              }
            }
          }
        }
      }
      props.setSelectedNodes(activeNodes);
    }
  }, [props.filterName]);

  React.useEffect(() => {
    let selectedNodes = [];
    for (let i = 0; i < props.data.length; i++) {
      const parent = props.data[i];
      selectedNodes.push(parent.id);
      for (let j = 0; j < parent.children.length; j++) {
        const child = parent.children[j];
        selectedNodes.push(child.id);
      }
    }
    props.setSelectedNodes(selectedNodes);
  }, [props.allListItemsActive])

    // Retrieve all ids from node to his children's
    function getAllIds(node, idList = []) {
      idList.push(node.id);
      if (node.children) {
        node.children.forEach((child) => getAllIds(child, idList));
      }
      return idList;
    }
    // Get IDs of all children from specific node
    const getAllChild = (id) => {
      return getAllIds(bfsSearch(props.data, id));
    };

    // Get all father IDs from specific node
    const getAllFathers = (id, list = []) => {
      const node = bfsSearch(props.data, id);
      if (node.parent) {
        list.push(node.parent);

        return getAllFathers(node.parent, list);
      }

      return list;
    };

    function isAllChildrenChecked(node, list) {
      const allChild = getAllChild(node.id);
      const nodeIdIndex = allChild.indexOf(node.id);
      allChild.splice(nodeIdIndex, 1);

      return allChild.every((nodeId) =>
        props.selectedNodes.concat(list).includes(nodeId)
      );
    }

    const handleNodeSelect = (event, nodeId) => {
      event.stopPropagation();
      const allChild = getAllChild(nodeId);
      const fathers = getAllFathers(nodeId);

      // if (props.selectedNodes.includes(nodeId)) {
      //   // Need to de-check
      //   props.setSelectedNodes((prevSelectedNodes) =>
      //     prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
      //   );
      // } else {
        // Need to check
        const ToBeChecked = allChild;
        for (let i = 0; i < fathers.length; ++i) {
          if (isAllChildrenChecked(bfsSearch(props.data, fathers[i]), ToBeChecked)) {
            ToBeChecked.push(fathers[i]);
          }
        }
        props.setSelectedNodes((prevSelectedNodes) =>
            ToBeChecked
          // [...prevSelectedNodes].concat(ToBeChecked)
        );
      props.setAllListItemsActive(false);
      // }
    };

    const handleExpandClick = (event) => {
      // prevent the click event from propagating to the checkbox
      event.stopPropagation();
    };

    const renderTree = (nodes) => (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        onClick={handleExpandClick}
        label={
          <Typography noWrap sx={{fontSize: !!nodes.children ? "1.1rem" : "1rem" }}>
            <Checkbox
              checked={props.selectedNodes.indexOf(nodes.id) !== -1}
              tabIndex={-1}
              disableRipple
              onClick={(event) => handleNodeSelect(event, nodes.id)}
            />
            {nodes.name}
          </Typography>
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  return (
    <Box id={"toolMarketplaceDetails"}>
      <TreeView
        multiSelect
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        selected={props.selectedNodes}
      >
        {props.data.sort((a, b) => a.name.localeCompare(b.name)).map((node) => renderTree(node))}
      </TreeView>
    </Box>
  );
}

ToolMarketplaceList.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.object),
  selectedUser: PropTypes.string,
  onClick: PropTypes.func,
  hideEmployees: PropTypes.arrayOf(PropTypes.string),
};

export default ToolMarketplaceList;