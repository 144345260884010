import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Avatar, ListItemText
} from "@mui/material";
import { DeleteOutlined, AddCircleOutline, AccountCircle } from "@mui/icons-material";
import PropTypes from "prop-types";
import ItemSearchList from "../../toolsAndServices/components/itemSearchList";
import Notification from "../../notification";
import {
  API_addEmployeeToolActivated,
  API_addEmployeeToolResponsibility,
  API_removeEmployeeToolActivated,
  API_removeEmployeeToolResponsibility,
} from "../../../services/backendService";
import {
  getCssValue,
  handleImageLoaded,
  updateToolsAndEmployees
} from "../../../services/utils";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const EmployeeTools = (props) => {
  const [showActivatedToolsList, setShowActivatedToolsList] = useState(false);
  const [showResponsibilityToolsList, setShowResponsibilityToolsList] = useState(false);
  const [hoveredTool, setHoveredTool] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const navigate = useNavigate();

  const goToTool = (toolId) => {
    for (let i = 0; i < props.tools.length; i++) {
      if (props.tools[i].id === toolId) {
        if (props.tools[i].status === "inTesting") {
          navigate("/testingtools?id=" + toolId)
        } else {
          navigate("/toolsandservices?id=" + toolId)
        }
      }
    }
  }

  const getIconAndName = (object, category) => {
    return (
      <ListItem disablePadding
                key={category + "-" + object.id}
                sx={{
                  p: "var(--detailsBoxPadding)",
                  maxHeight: "64px"
                }}
                onClick={() => goToTool(object.tools_id.id)}
      >
        <ListItemButton
          onMouseEnter={() => setHoveredTool(object.id)}
          onMouseLeave={() => setHoveredTool(null)}
        >
          <ListItemIcon>
            {object.tools_id.tool_synced.logo ? (
              <Avatar
                key={object.tools_id.tool_synced.logo.id}
                alt={object.tools_id.tool_synced.name}
                src={"assets/loading.svg"}
                onLoad={(event) =>
                  handleImageLoaded(
                    event,
                    object.tools_id.tool_synced.logo.id,
                    "?height=" +
                    (getCssValue("--lineHeight") -
                      2 * getCssValue("--detailsBoxPadding"))
                  )
                }
                sx={{
                  height:
                    "calc(var(--lineHeight) - 2*var(--detailsBoxPadding))",
                  width:
                    "calc(var(--lineHeight) - 2*var(--detailsBoxPadding))",
                }}
              />
            ) : (
              <AccountCircle
                sx={{
                  fontSize:
                    "calc(var(--lineHeight) - 2*var(--detailsBoxPadding))",
                }}
              />
            )}
          </ListItemIcon>
          <ListItemText
            primary={object.tools_id.tool_synced.name}
          />
          {hoveredTool === object.id && <DeleteOutlined
            sx={{ cursor: "pointer" }}
            onClick={() => removeElement(category, object)}
          />}
        </ListItemButton>
      </ListItem>
    );
  };

  const updateView = () => {
    updateToolsAndEmployees(props.setEmployees, props.setTools, props.setLoading);
    setShowActivatedToolsList(false);
    setShowResponsibilityToolsList(false);
  };

  const addElement = (toolId, category) => {
    if (category === "activated") {
      //activated
      API_addEmployeeToolActivated(props.currentEmployeeId, toolId).then(() => {
        updateView();
      });
    } else if (category === "responsibility") {
      // responsibility
      API_addEmployeeToolResponsibility(props.currentEmployeeId, toolId).then(
        () => {
          updateView();
        }
      );
    }
  };

  const removeElement = (toolCategory, object) => {
    if (toolCategory === "activated") {
      API_removeEmployeeToolActivated(object.id).then(() => {
        updateView();
      });
    } else if (toolCategory === "responsibility") {
      API_removeEmployeeToolResponsibility(object.id).then(() => {
        updateView();
      });
    }
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      {!!props.tools && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            m: "var(--detailsBoxPadding) var(--detailsBoxPadding) 0 var(--detailsBoxPadding)",
          }}
        >
          <Box
            className={"card"}
            sx={{
              mr: "calc(var(--detailsBoxPadding) / 2)",
              mb: "var(--detailsBoxPadding)",
              width: "calc(50% - 2.5 * var(--detailsBoxPadding))"
            }}
          >
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <Typography
                variant="h5"
                sx={{ lineHeight: "var(--lineHeight)", display: "flex" }}
              >
                {t("employee_toolActivated")}
              </Typography>
              <Button variant="outlined"
                      sx={{height: "35px"}}
                      startIcon={<AddCircleOutline />}
                      onClick={() =>
                        setShowActivatedToolsList(!showActivatedToolsList)
                      }
              >
                {t('add')}
              </Button>
            </Box>
            {showActivatedToolsList && (
              <ItemSearchList
                allItems={props.tools
                  .map(function (item) {
                    return {
                      id: item.id,
                      logo: {
                        id: item.tool_synced.logo
                          ? item.tool_synced.logo.id
                          : null,
                      },
                      name: item.tool_synced.name,
                    };
                  })
                  .filter(function (itemToFilter) {
                    for (let i = 0; i < props.activated.length; i++) {
                      if (
                        itemToFilter.id === props.activated[i].tools_id.id
                      ) {
                        return false;
                      }
                    }
                    return true;
                  })
              }
                addElement={addElement}
                closeList={() => setShowActivatedToolsList(false)}
                toolCategory={"activated"}
              />
            )}
            <List
              disablePadding
              sx={{minHeight: "100%"}}
            >
              {props.activated.map(function (object) {
                return getIconAndName(object, "activated");
              })}
            </List>
          </Box>
          <Box
            className={"card"}
            sx={{
              ml: "calc(var(--detailsBoxPadding) / 2)",
              mb: "var(--detailsBoxPadding)",
              width: "calc(50% - 2.5 * var(--detailsBoxPadding))"
            }}
          >
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <Typography
                variant="h5"
                sx={{ lineHeight: "var(--lineHeight)", display: "flex" }}
              >
                {t("employee_toolResponsibility")}
              </Typography>
              <Button variant="outlined"
                      sx={{height: "35px"}}
                      startIcon={<AddCircleOutline />}
                      onClick={() =>
                        setShowResponsibilityToolsList(!showResponsibilityToolsList)
                      }
              >
                {t('add')}
              </Button>
            </Box>
            {showResponsibilityToolsList && (
              <ItemSearchList
                allItems={props.tools
                  .map(function (item) {
                    return {
                      id: item.id,
                      logo: {
                        id: item.tool_synced.logo
                          ? item.tool_synced.logo.id
                          : null,
                      },
                      name: item.tool_synced.name,
                    };
                  })
                  .filter(function (itemToFilter) {
                    for (let i = 0; i < props.responsibility.length; i++) {
                      if (
                        itemToFilter.id ===
                        props.responsibility[i].tools_id.id
                      ) {
                        return false;
                      }
                    }
                    return true;
                  })}
                addElement={addElement}
                closeList={() => setShowResponsibilityToolsList(false)}
                toolCategory={"responsibility"}
              />
            )}
            {props.responsibility.map(function (object) {
              return getIconAndName(object, "responsibility");
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

EmployeeTools.propTypes = {
  currentEmployeeId: PropTypes.string,
  activated: PropTypes.arrayOf(
    PropTypes.shape({
      tools_id: PropTypes.object,
    })
  ),
  responsibility: PropTypes.arrayOf(
    PropTypes.shape({
      tools_id: PropTypes.object,
    })
  ),
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      id: PropTypes.string,
      fields: PropTypes.object,
    })
  ),
  toolsWidth: PropTypes.string,
  setTools: PropTypes.func,
  setLoading: PropTypes.func,
  setNotify: PropTypes.func,
  setEmployees: PropTypes.func,
};

export default EmployeeTools;
