import React from "react";
import { Box } from "@mui/system";
import {
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ToolBox from "./toolMarketplace/components/toolBox";
import ToolDetailsSidebar from "./toolsAndServices/components/toolDetailsSidebar";

const PasswordPrivacy = (props) => {

  const { t } = useTranslation();
  const [sidebarData, setSidebarData] = React.useState(false);

  return (
    <Box>
      <Box sx={{p: "0 var(--listsPadding) var(--listsPadding) var(--listsPadding)", maxWidth: "1200px"}}>
        <Typography variant={"h5"} sx={{ lineHeight: "var(--lineHeight)" }}>
          {t(props.header)}
        </Typography>
        <Box sx={{display: "flex", flexWrap: "wrap"}}>
          {props.data.map((tool) => {
            return <ToolBox key={tool.id} tool={tool} tools={props.tools} setLoading={props.setLoading} setTools={props.setTools} setSidebarData={setSidebarData} />
          })}
        </Box>
      </Box>
      {!!sidebarData && <ToolDetailsSidebar closeSidebar={() => setSidebarData(false)} tool={sidebarData} isLoggedin={props.isLoggedin}
                                            setLoading={props.setLoading} tools={props.tools} setTools={props.setTools}/>}
    </Box>
  );
};

PasswordPrivacy.propTypes = {
  data: PropTypes.array,
};

export default PasswordPrivacy;