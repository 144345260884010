import React, { useState } from "react";
import PropTypes from "prop-types";
import {Box, Button, Dialog, DialogTitle, Typography} from "@mui/material";
import Notification from "../../notification";
import RightPlusButton from "../../rightPlusButton";
import ItemSearchList from "./itemSearchList";
import { t } from "i18next";
import { API_customerAddNewTool } from "../../../services/backendService";
import {useLocation, useNavigate} from "react-router-dom";
import DynamicTextButton from "../../common/dynamicTextButton";

function Header(props) {
  const [showAllToolsList, setShowAllToolsList] = React.useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const location = useLocation();
  const [showPrivacyChooserOverlay, setShowPrivacyChooserOverlay] = React.useState(false);
  const navigate = useNavigate();

  function addElement(id) {
    setShowAllToolsList(false);
    props.addTool(id);
  }

  function addnewToolFromCustomer(website) {
    API_customerAddNewTool(website).then(() => {
      setShowAllToolsList(false);
      setNotify({
        isOpen: true,
        message: "Das Tool wurde erfolgreich an uns weitergeleitet.",
        type: "success",
      });
    });
  }

  function privacyClicked() {
    switch (props.privacyTools.length) {
      case 0:
        navigate("/privacy");
        break;
      case 1:
        window.open(props.privacyTools[0].tool_synced.url_login, '_blank');
        break;
      default:
        setShowPrivacyChooserOverlay(true)
    }
  }

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <Dialog open={showPrivacyChooserOverlay}>
        <DialogTitle>
          Welches Tool soll geöffnet werden?
        </DialogTitle>
        <Box>
          {props.privacyTools.map(function (object) {
            return <Box key={object.id} display="flex" justifyContent="flex-start" p="var(--detailsBoxPadding)" cursor="hover" onClick={() => {
              window.open(object.tool_synced.url_login, '_blank');
              setShowPrivacyChooserOverlay(false)
            }}>
              {!!object.tool_synced.logo && <Box
                  component="img"
                  sx={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                    pr: "var(--detailsBoxPadding)"
                  }}
                  alt=""
                  src={
                      import.meta.env.VITE_API_URL +
                      "/assets/" + object.tool_synced.logo.id + "?height=40"
                  }
              /> }
              <Box>{object.tool_synced.name}</Box>
            </Box>
          })}
        </Box>
      </Dialog>
      <Box
        padding={0}
        sx={{
          height: "var(--lineHeight)",
          display: "flex",
          alignItems: "center",
          pl: "var(--listsPadding)",
        }}
      >
        <Box
          sx={{width: { xs: "100%", sm: "20%" }, display: "flex", justifyContent: "space-between", height: "40px"}}
          id={"boxWithHeaderAndAddButton"}
        >
          {/*<ArrowBack onClick={() => props.handleArrowBackClick()} />*/}
          <Typography variant={"h5"} sx={{pl: "var(--listsPadding)"}}>{props.header}</Typography>
          <DynamicTextButton
            functions={() => setShowAllToolsList(true)}
          />
        </Box>
        <Box sx={{ml: "var(--listsPadding)", display: "flex", height: "40px", justifyContent: "space-between", width: "78%"}}>
          <Box sx={{display: "flex"}}>
            <Button variant={props.isDetailsVisible ? "contained" : "outlined"} href={location.pathname + '#top'} onClick={() => props.setActiveTab("details")} >
              {t('tools_overview')}
            </Button>
            {props.showRolloutProcess &&
              <Button variant="outlined" onClick={() => props.setActiveTab("board")} sx={{ml: "var(--listsPadding)"}} >
                {t('toolsServices_rollout')}
              </Button>
            }
            <Button variant={props.isInvoicesVisible ? "contained" : "outlined"} href={location.pathname + '#invoices'} sx={{ml: "var(--listsPadding)"}} >
              {t('toolsServices_invoices')}
            </Button>
            <Button variant={props.isEmployeesVisible ? "contained" : "outlined"} href={location.pathname + '#userBoxAtTools'} sx={{ml: "var(--listsPadding)"}} onClick={() => props.setActiveTab("details")} >
              {t('menu_employees')}
            </Button>
            <Button variant="outlined" disabled={props.privacyTools.length < 0} sx={{ml: "var(--listsPadding)"}} onClick={() => privacyClicked()}>
              {t('menu_privacy')}
            </Button>
            <Button variant="outlined" disabled={true} sx={{ml: "var(--listsPadding)"}} >
              {t('toolsServices_contracts')}
            </Button>
          </Box>
          <Box sx={{display: "flex"}}>
            <Button variant="outlined"
                    disabled={!props.urlWeb}
                    onClick={() => window.open(props.urlWeb,'_blank')}
                    sx={{ml: "var(--listsPadding)"}} >
              {t('toolsServices_website')}
            </Button>
            <Button variant="outlined"
                    disabled={!props.urlLogin}
                    onClick={() => window.open(props.urlLogin,'_blank')}
                    sx={{ml: "var(--listsPadding)"}} >
              {t('toolsServices_login')}
            </Button>
          </Box>
        </Box>
      </Box>
      <RightPlusButton
        plusFunction={() => {
          setShowAllToolsList(true);
        }}
      />
      {showAllToolsList && !!props.syncedTools && (
        <ItemSearchList
          allItems={props.syncedTools}
          setNotify={setNotify}
          addElement={addElement}
          closeList={() => setShowAllToolsList(false)}
          addnewToolFromCustomer={addnewToolFromCustomer}
        />
      )}
    </>
  );
}

Header.propTypes = {
  toolName: PropTypes.string,
  addTool: PropTypes.func,
  syncedTools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape({
        Logo: PropTypes.array,
        Name: PropTypes.string,
      }),
    })
  ),
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  header: PropTypes.string,
  showRolloutProcess: PropTypes.bool,
  privacyTools: PropTypes.array
};

export default Header;
