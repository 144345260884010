import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box, Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Toolbar, Typography
} from "@mui/material";
import { Apps, Close } from "@mui/icons-material";
import { handleImageLoaded } from "../../../services/utils";
import { t } from "i18next";

function ItemSearchList(props) {
  const [resultItems, setResultItems] = useState(props.allItems);
  const [newToolWebsite, setNewToolWebsite] = useState("");

  const updateSearchField = (search) => {
    setResultItems(
      props.allItems.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  return (
    <Dialog
      fullScreen
      open={true}
      sx={{ width: "calc(var(--lineHeight) * 8)", left: "auto" }}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "primary.main",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              props.closeList();
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <TextField
            autoFocus
            label={t("search") + "..."}
            variant="standard"
            onChange={(event) => updateSearchField(event.target.value)}
            sx={{
              "& label.Mui-focused": {
                color: "primary.contrastText",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.contrastText", //underline when focused
              },
              "& .MuiInputLabel-root": { color: "primary.contrastText" }, //labelFarbe,
              input: {color: "primary.contrastText"}
            }}
          />
        </Toolbar>
      </AppBar>
      <List disablePadding>
        {resultItems.map(function (tool) {
          return [
            <ListItemButton
              key={"button-" + tool.id}
              sx={{
                lineHeight: "var(--lineHeight)",
                p: "0 var(--listsPadding)",
              }}
              onClick={() => {
                props.addElement(tool.id, props.toolCategory);
              }}
            >
              <ListItemAvatar>
                {!!tool.logo && tool.logo.id !== null ? (
                  <Box
                    component="img"
                    loading={"lazy"}
                    sx={{ height: 25, width: 25, pr: "5px" }}
                    alt={tool.name}
                    src={"assets/loading.svg"}
                    onLoad={(event) =>
                      handleImageLoaded(
                        event,
                        tool.logo.id,
                        "?width=25&height=25"
                      )
                    }
                  />
                ) : (
                  <Apps />
                )}
              </ListItemAvatar>
              <ListItemText
                sx={{ lineHeight: "var(--lineHeight)" }}
                primary={tool.name}
              />
            </ListItemButton>,
            <Divider key={"divider-" + tool.id} />,
          ];
        })}
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-end", p: "var(--listsPadding)"}}>
          <Typography sx={{width: "100%", color: "primary.main"}}>{t('toolsServices_toolNotFoundEnterWebsite')}</Typography>
          <TextField variant="outlined"
                     label={t('toolsServices_toolWebsiteExample')}
                     value={newToolWebsite}
                     onChange={(event) => {setNewToolWebsite(event.target.value)}}
                     sx={{m: "var(--listsPadding) 0", width: "100%"}} />
          <Button variant="outlined"
                  onClick={() => props.addnewToolFromCustomer(newToolWebsite)}>{t('button_submit')}</Button>
        </Box>
      </List>
    </Dialog>
  );
}

ItemSearchList.propTypes = {
  allItems: PropTypes.array,
  addElement: PropTypes.func,
  toolCategory: PropTypes.string,
  closeList: PropTypes.func,
};

export default ItemSearchList;
