import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";
import { AccountCircle } from "@mui/icons-material";
import { handleImageLoaded } from "../../../services/utils";

function PaymentsList(props) {
  return (
    <List disablePadding
          sx={{minHeight: "100%"}}
    >
      {!!props.payments &&
        props.payments.map(function (payment, i) {
          return (
            <ListItem disablePadding
                      key={payment.id}
                      sx={{
                        p: "var(--detailsBoxPadding)",
                        maxHeight: "64px"
                      }}
            >
              <ListItemButton
                selected={props.selectedPayment === i}
                onClick={() => props.onClick(i)}
              >
                <ListItemIcon sx={{minWidth: "50px"}}>
                  {payment.logo !== null ? (
                    <Avatar
                      alt={
                        import.meta.env.VITE_API_URL +
                        "/assets/" +
                        payment.name
                      }
                      src={"assets/loading.svg"}
                      sx={{ height: '40px', width: '40px' }}
                      onLoad={(event) =>
                        handleImageLoaded(
                          event,
                          payment.logo.id,
                          "?width=40&height=40"
                        )
                      }
                    />
                  ) : (
                    <AccountCircle sx={{ fontSize: 40 }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={payment.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}

PaymentsList.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  selectedPayment: PropTypes.number,
  onClick: PropTypes.func,
};

export default PaymentsList;
