import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Avatar, Typography, Tooltip } from "@mui/material";
import {
  AccountCircle,
  Apps,
  Check, KeyboardArrowDown, KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp
} from "@mui/icons-material";
import { getCssValue, handleImageLoaded, updateToolsAndEmployees } from "../../../services/utils";
import {
  API_addEmployeeToolActivated,
  API_removeEmployeeToolActivated,
} from "../../../services/backendService";
import { t } from "i18next";
import "../css/matrix.css";
import { useNavigate } from "react-router-dom";

const Matrix = (props) => {
  const [arrowUp, setArrowUp] = useState(false);
  const [arrowDown, setArrowDown] = useState(true);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(true);
  const navigate = useNavigate();

  let topScroll = React.createRef();
  let sidebarScroll = React.createRef();
  let contentScroll = React.createRef();

  let _preventEvent = false;
  let contentLastScrollTop = 0;
  let contentLastScrollLeft = 0;

  const onTopScroll = e => {
    if (_preventEvent) {
      _preventEvent = false;
      return;
    }

    _preventEvent = true;
    contentScroll.current.scrollLeft = e.target.scrollLeft;
    setArrowLeft(e.target.scrollLeft !== 0);
    setArrowRight(e.target.scrollLeft !== (e.target.scrollWidth - e.target.offsetWidth));
  };

  const onSidebarScroll = (e) => {
    if (_preventEvent) {
      _preventEvent = false;
      return;
    }

    _preventEvent = true;
    contentScroll.current.scrollTop = e.target.scrollTop;
    setArrowUp(e.target.scrollTop !== 0);
    setArrowDown(e.target.scrollTop !== (e.target.scrollHeight - e.target.offsetHeight));
  };

  const onContentScroll = e => {
    if (_preventEvent) {
      _preventEvent = false;
      return;
    }

    if (e.target.scrollTop !== contentLastScrollTop) {
      _preventEvent = true;
      sidebarScroll.current.scrollTop = e.target.scrollTop;
      contentLastScrollTop = e.target.scrollTop;
      setArrowUp(e.target.scrollTop !== 0);
      setArrowDown(e.target.scrollTop !== (e.target.scrollHeight - e.target.offsetHeight));
    }
    if (e.target.scrollLeft !== contentLastScrollLeft) {
      _preventEvent = true;
      topScroll.current.scrollLeft = e.target.scrollLeft;
      contentLastScrollLeft = e.target.scrollLeft;
      setArrowLeft(e.target.scrollLeft !== 0);
      setArrowRight(e.target.scrollLeft !== (e.target.scrollWidth - e.target.offsetWidth));
    }
  };


  React.useEffect(() => {
    let elements = document.getElementsByClassName("toolsLogo");
    for (let i = 0; i < elements.length; i++) {
      elements[i].src = "assets/loading.svg";
    }
    if (contentScroll.current !== null) {
      setArrowDown(contentScroll.current.scrollTop !== (contentScroll.current.scrollHeight - contentScroll.current.offsetHeight));
      setArrowRight(contentScroll.current.scrollLeft !== (contentScroll.current.scrollWidth - contentScroll.current.offsetWidth));
    }
  }, [props.tools]);

  const removeTool = (tool, employeeId) => {
    props.setLoading(true);
    for (let i = 0; i < tool.employees_activated.length; i++) {
      if (
        tool.employees_activated[i].tools_id.id === tool.id &&
        tool.employees_activated[i].employees_id.id === employeeId
      ) {
        API_removeEmployeeToolActivated(tool.employees_activated[i].id).then(
          () => {
            updateToolsAndEmployees(
              props.setEmployees,
              props.setTools,
              props.setLoading
            );
          }
        );
      }
    }
  };

  const addTool = (toolId, employeeId) => {
    props.setLoading(true);
    API_addEmployeeToolActivated(employeeId, toolId).then(() => {
      updateToolsAndEmployees(
        props.setEmployees,
        props.setTools,
        props.setLoading
      );
    });
  };

  function checkIfToolIsActivated(activatedTools, id) {
    let result = false;
    for (let i = 0; i < activatedTools.length; i++) {
      if (activatedTools[i].tools_id.id === id) {
        result = true;
      }
    }
    return result;
  }

  return (
    <Box className={"cardShadow"}>
      {props.tools.length === 0 && <Typography variant="body2" sx={{p: "var(--listsPadding)"}}>{t('employeeToolsMatrix_noTools')}</Typography>}
      {props.tools.length > 0 && <Box className="container" sx={{p: "0"}}>
        <Box className="row-1" sx={{display: "flex"}}>
          <Box className="row-1-placeholder" />
          <Box
            id="top-scroll"
            ref={topScroll}
            className="top-scroll scollContainer"
            onScroll={(event) => onTopScroll(event)}
            sx={{background: "white",
              display: "flex",
              overflowX: "auto",
              position: "relative",
              borderRadius: "6px"
            }}
          >
            {arrowLeft && <Box className={"scrollArrowBox"} sx={{top: "calc(50% - 20px)", left: "8px", zIndex: 10}}>
              <KeyboardArrowLeft className={"scrollArrow"} onClick={() => contentScroll.current.scrollLeft = contentScroll.current.scrollLeft - 100} />
            </Box>}
            {arrowRight && <Box className={"scrollArrowBox"} sx={{top: "calc(50% - 20px)", right: "8px", zIndex: 10}}>
              <KeyboardArrowRight className={"scrollArrow"} onClick={() => contentScroll.current.scrollLeft = contentScroll.current.scrollLeft + 100} />
            </Box>}
            {props.employees.map(function (employee, j) {
              return (<Tooltip title={employee.firstname + " " + employee.lastname} key={employee.id} placement="top">
                <Box className={"item"} onClick={() => navigate("/employeesItem?id=" + employee.id)} sx={{cursor: "pointer"}}>
                  {employee.avatar ? (
                    <Avatar
                      key={j}
                      alt={employee.avatar.name}
                      src={"assets/loading.svg"}
                      onLoad={(event) =>
                        handleImageLoaded(
                          event,
                          employee.avatar.id,
                          "?width=40&height=40"
                        )
                      }
                      sx={{
                        fontSize: 40,
                      }}
                    />
                  ) : (
                    <AccountCircle sx={{ fontSize: 40 }} key={j} />
                  )}
              </Box></Tooltip>)})}
          </Box>
        </Box>
        <Box className="row-2" style={{display: "flex"}}>
          <Box
            id="sidebar-scroll"
            ref={sidebarScroll}
            className="sidebar-scroll scollContainer"
            onScroll={(event) => onSidebarScroll(event)}
            sx={{background: "#ccc",
              overflowY: "auto",
              position: "relative",
              borderRadius: "6px"
          }}
          >
            {arrowUp && <Box className={"scrollArrowBox"} sx={{left: "calc(50% - 20px)", top: "8px"}}>
              <KeyboardArrowUp className={"scrollArrow"} onClick={() => contentScroll.current.scrollTop = contentScroll.current.scrollTop - 100} />
            </Box>}
            {arrowDown && <Box className={"scrollArrowBox"} sx={{left: "calc(50% - 20px)", bottom: "8px"}}>
              <KeyboardArrowDown className={"scrollArrow"} onClick={() => contentScroll.current.scrollTop = contentScroll.current.scrollTop + 100} />
            </Box>}
            <Box>
              {props.tools.length > 0 && props.tools.map(function (tool, i) {
                return (
                  <Box
                    key={tool.id}
                    onClick={() => tool.status === "inTesting" ? navigate("/testingtools?id=" + tool.id) : navigate("/toolsandservices?id=" + tool.id)}
                    sx={{
                      backgroundColor: i % 2 === 0 ? "#f5f5f3" : "white",
                      lineHeight: "var(--lineHeight)",
                      p: "0 var(--listsPadding)",
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "250px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer"
                    }}
                  >
                    {tool.tool_synced.logo !== null ? (
                      <Box
                        component="img"
                        sx={{
                          maxHeight: "calc(var(--lineHeight) - var(--listsPadding))",
                          maxWidth: "calc(var(--lineHeight) - var(--listsPadding))",
                        }}
                        alt={tool.tool_synced.name}
                        src={"assets/loading.svg"}
                        className={"toolsLogo"}
                        onLoad={(event) =>
                          handleImageLoaded(
                            event,
                            tool.tool_synced.logo.id,
                            "?width=" +
                            getCssValue("--lineHeight")
                          )
                        }
                      />
                    ) : (
                      <Apps sx={{ fontSize: 40 }} />
                    )}
                    <Typography
                      noWrap
                      sx={{
                        lineHeight: "var(--lineHeight)",
                        pl: "var(--listsPadding)",
                      }}
                    >
                      {tool.tool_synced.name}
                    </Typography>
                  </Box>)})}
            </Box>
          </Box>
          <Box
            id="content-scroll"
            ref={contentScroll}
            className="content-scroll scollContainer"
            onScroll={(event) => onContentScroll(event)}
            sx={{background: "#ccc",
              overflowY: "auto",
              overflowX: "auto",
              display: "flex",
              position: "relative",
              borderRadius: "6px",
              backgroundColor: "transparent"
          }}
          >
            {props.employees.map(function (employee, j) {
              return (<Box key={employee.id + "-" + j}>
                {props.tools.length > 0 && props.tools.map(function (tool, i) {
                  return (<Box key={employee.id + tool.id} className={"item"} sx={{lineHeight: "var(--lineHeight)",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: i % 2 === 0 ? "#f5f5f3" : "white"
                  }}
                               onClick={() =>
                                 checkIfToolIsActivated(
                                   employee.tools_activated,
                                   tool.id
                                 )
                                   ? removeTool(tool, employee.id)
                                   : addTool(tool.id, employee.id)
                               }
                  >
                    {!!employee.tools_activated &&
                    checkIfToolIsActivated(
                      employee.tools_activated,
                      tool.id
                    ) ? (
                      <Check
                        sx={{
                          fontSize: 30,
                        }}
                      />
                    ) : (
                      <Box>&nbsp;</Box>
                    )}
                  </Box>)})}
              </Box>)
            })}
          </Box>
        </Box>
      </Box>}
    </Box>
  );
};

Matrix.propTypes = {
  employees: PropTypes.array,
  tools: PropTypes.array,
  setLoading: PropTypes.func,
  setTools: PropTypes.func,
  setEmployees: PropTypes.func,
};

export default Matrix;
