import React from "react";
import PropTypes from "prop-types";
import {
  Box, Button,
  Dialog,
  Typography
} from "@mui/material";
import { Apps, Close } from "@mui/icons-material";
import { handleImageLoaded, handleImageLoadedWithoutCredentials } from "../../../services/utils";
import { t } from "i18next";
import { API_addTool } from "../../../services/backendService";
import { useNavigate } from "react-router-dom";

function ToolDetailsSidebar(props) {

  const navigate = useNavigate();

  function addTool() {
    if (!props.isLoggedin) {
      navigate("/register");
    } else {
      props.setLoading(true);
      API_addTool(props.tool.id).then(function(response) {
        let allNewTools = props.tools
          .concat(response)
          .sort((a, b) => a.tool_synced.name.localeCompare(b.tool_synced.name));
        props.setTools(allNewTools);
        props.setLoading(false);
        navigate("/toolsandservices?id=" + response.id)
      });
    }
  }

  function addToolTesting() {
    if (!props.isLoggedin) {
      navigate("/register");
    } else {
      props.setLoading(true);
      API_addTool(props.tool.id, "inTesting").then(function(response) {
        let allNewTools = props.tools
          .concat(response)
          .sort((a, b) => a.tool_synced.name.localeCompare(b.tool_synced.name));
        props.setTools(allNewTools);
        props.setLoading(false);
        navigate("/testingtools?id=" + response.id)
      });
    }
  }

  return (
    <Dialog
      fullScreen
      open={true}
      sx={{ width: "calc(var(--lineHeight) * 8)", left: "auto" }}
    >
      <Box sx={{p: "0 var(--detailsBoxPadding)"}}>
        <Box sx={{display: "flex", justifyContent: "space-between", height: "64px", alignItems: "center"}}>
          <Box sx={{display: "flex"}}>
            {props.tool.logo ? (
              <Box
                component="img"
                sx={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "6px",
                }}
                alt={props.tool.name}
                src={"assets/loading.svg"}
                onLoad={(event) =>
                  {
                    if (!!props.tools) {
                      handleImageLoaded(
                        event,
                        props.tool.logo.id,
                        "?width=25&height=25"
                      )
                    } else {
                      handleImageLoadedWithoutCredentials(
                        event,
                        props.tool.logo.id,
                        "?width=25&height=25"
                      )
                    }
                  }
                }
              />
            ) : (
              <Apps sx={{ fontSize: "var(--detailsBoxWidth)" }} />
            )}
            <Typography variant={"h5"} sx={{ml: "14px"}}>{props.tool.name}</Typography>
          </Box>
          <Close sx={{cursor: "pointer"}} onClick={() => props.closeSidebar()}/>
        </Box>
        {!!props.tool.translations_shortDescription[0] && <Typography sx={{
          fontSize: "1.1rem",
          lineHeight: "1.6rem",
          backgroundColor: "var(--background)",
          p: "var(--detailsBoxPadding)",
          borderRadius: "6px"
        }}>{props.tool.translations_shortDescription[0].shortDescription}</Typography>}
        <Typography sx={{fontSize: "1.3rem", pt: "24px", lineHeight: "1.6rem"}}>{t('toolMarketplace_saveToolInWorketplace')}</Typography>
        <Typography sx={{pt: "calc(var(--detailsBoxPadding) / 2)"}}>{t('toolMarketplace_marketingWorkplace')}</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between", pt: "var(--detailsBoxPadding)"}}>
          <Button variant={"outlined"} sx={{width: "48%"}} onClick={() => addToolTesting()}>
            {t('toolMarketplace_saveInTests')}
          </Button>
          <Button variant={"outlined"} sx={{width: "48%"}} onClick={() => addTool()}>
            {t('toolMarketplace_saveInTools')}
          </Button>
        </Box>
        <Typography sx={{fontSize: "1.3rem", pt: "24px"}}>{t('toolMarketplace_getToKnowTool')}</Typography>
        <Typography sx={{pt: "calc(var(--detailsBoxPadding) / 2)", lineHeight: "1.6rem"}}>{t('toolMarketplace_marketingReview')}</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between", pt: "var(--detailsBoxPadding)"}}>
          <Button variant={"outlined"} sx={{width: "48%"}}
                  onClick={() => !!props.tool.url_web ? window.open(props.tool.url_web,'_blank') : ""}
                  disabled={!props.tool.url_web}>
            {t('passwordPrivacy_toWebsite')}
          </Button>
          <Button variant={"outlined"} sx={{width: "48%"}} onClick={() => !!props.tool.url_review ? window.open(props.tool.url_review,'_blank') : ""}
                  disabled={!props.tool.url_review}>
            {t('toolMarketplace_toReview')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

ToolDetailsSidebar.propTypes = {
  closeSidebar: PropTypes.func,
};

export default ToolDetailsSidebar;