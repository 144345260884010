import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import PaymentsList from "./components/paymentsList";
import PaymentDetails from "./components/paymentDetails";
import PaymentsHeader from "./components/paymentsHeader";
import { Scrollbars } from "react-custom-scrollbars";

function PaymentProvider(props) {
  const [selectedPayment, setSelectedPayment] = React.useState(0);

  const handleListPaymentsClick = (value) => {
    setSelectedPayment(value);
  };

  return (
    <>
      <PaymentsHeader
        name={
          props.payments.length > 0 ? props.payments[selectedPayment].name : ""
        }
      />
      <Box sx={{position: "relative", height: "calc(100% - var(--lineHeight))", pl: "8px"}}>
        <Box sx={{position: "absolute", width: { xs: "100%", sm: "20%" }, overflow: "scroll", top: 0, bottom: 0, left: "var(--detailsBoxPadding)"}} className={"hideScrollbar cardShadow"}>
          <Scrollbars>
            <PaymentsList
              payments={props.payments}
              selectedPayment={selectedPayment}
              onClick={(value) => handleListPaymentsClick(value)}
            />
          </Scrollbars>
        </Box>
        {!!props.payments[selectedPayment] && <Box sx={{position: "absolute", width: { xs: "0%", sm: "calc(80% - var(--detailsBoxPadding))" }, overflow: "scroll", top: 0, bottom: 0, right: 0}} className={"hideScrollbar"}>
          <Scrollbars>
            <PaymentDetails
              payment={props.payments[selectedPayment]}
            />
          </Scrollbars>
        </Box>}
      </Box>
    </>
  );
}

export default PaymentProvider;

PaymentProvider.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
};
