import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const CostsPerX = (props) => {
  let totalCostsPerYear = 0;
  if (props.costs) {
    for (let i = props.costs.length; i--; ) {
      totalCostsPerYear += props.costs[i];
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: props.backgroundColor,
        borderRadius: "6px",
        p: "var(--listsPadding)",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" sx={{fontWeight: "bold"}}>
        {(Math.round(totalCostsPerYear * 100) / 100).toLocaleString()}{" "}
        {props.currencySign ? props.currencySign : ""}
      </Typography>
      <Typography variant="subtitle2">{props.description}</Typography>
    </Box>
  );
};

CostsPerX.propTypes = {
  costs: PropTypes.arrayOf(PropTypes.number),
  backgroundColor: PropTypes.string,
  currencySign: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.string,
};

export default CostsPerX;
