import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { Box } from "@mui/system";
import { Clear  } from "@mui/icons-material";

function ToolMarketplaceHeader(props) {

  const [filterName, setFilterName] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.setFilterName(filterName);
    }, 200)

    return () => clearTimeout(delayDebounceFn)
  }, [filterName])

  return (
    <>
      <Box
        padding={0}
        paddingTop={props.isLoggedin ? "0" : "20px"}
        sx={{
          height: "var(--lineHeight)",
          display: "flex",
          alignItems: "center",
          pl: "var(--listsPadding)",
          mb: "16px"
        }}
      >
        <Box sx={{width: { xs: "100%", sm: "20%" }, display: "flex", alignItems: "center", justifyContent: "space-between", height: "40px"}}>
          {/*<ArrowBack onClick={() => props.handleArrowBackClick()} />*/}
          <Typography variant={"h5"} sx={{pl: "var(--listsPadding)"}}>{t("menu_toolMarketplace")}</Typography>
          <Button variant={"text"} onClick={() => props.setAllListItemsActive(true)}>{t('all')}</Button>
        </Box>
        <Box sx={{ml: "var(--listsPadding)", display: "flex"}}>
          <TextField
            label={t("search")}
            type={"text"}
            value={filterName}
            onChange={(event) => setFilterName(event.target.value)}
            sx={{
              "fieldset": {border: "1px solid transparent"},
              width: "550px",
              ".MuiFormLabel-root": {
                color: '#747474',
              },
              ".MuiInputBase-root": {
                backgroundColor: "#E6E6E6"
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Clear sx={{cursor: "pointer"}} onClick={() => setFilterName("")} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
}

ToolMarketplaceHeader.propTypes = {
  minWidth600: PropTypes.bool,
  showDetails: PropTypes.bool,
  handleArrowBackClick: PropTypes.func,
  addEmployee: PropTypes.func,
};

export default ToolMarketplaceHeader;
