import React from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import deLocale from "@fullcalendar/core/locales/de";
import { t } from "i18next";

const Timeline = (props) => {
  const removeTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const getAllEvents = async () => {
    let fullcalendarEvents = [];
    let dataCopy = JSON.parse(JSON.stringify(props.data));
    for (let i = 0; i < dataCopy.length; i++) {
      const event = dataCopy[i];
      fullcalendarEvents.push({
        resourceId: event.duration_of_contract,
        title: event.title,
        start: removeTime(new Date(event.start)),
        end: removeTime(new Date(event.end)),
        color: event.color,
        status: event.status,
        sortOrder: event.sortOrder
      });
    }
    return fullcalendarEvents;
  };

  const timelineDidMount = () => {
    let timeline = document.getElementById("timeline");
    // header mit Zeiten scrollen
    timeline
      .getElementsByClassName("fc-scroller")[1]
      .addEventListener("scroll", function () {
        // events scrollen horizontal
        timeline.getElementsByClassName(
          "fc-scroller-liquid-absolute"
        )[1].scrollLeft = this.scrollLeft;
      });
    // events scrollen horizontal
    timeline
      .getElementsByClassName("fc-scroller-liquid-absolute")[1]
      .addEventListener("scroll", function () {
        // header mit Zeiten scrollen
        timeline.getElementsByClassName("fc-scroller")[1].scrollLeft =
          this.scrollLeft;
        // ressourcen scrollen vertikal
        timeline.getElementsByClassName(
          "fc-scroller-liquid-absolute"
        )[0].scrollTop = this.scrollTop;
      });
    // Spalte mit Ressources scrollen
    timeline
      .getElementsByClassName("fc-scroller-liquid-absolute")[0]
      .addEventListener("scroll", function () {
        // events scrollen vertikal
        timeline.getElementsByClassName(
          "fc-scroller-liquid-absolute"
        )[1].scrollTop = this.scrollTop;
      });
  };

  const updatePositionNowIndicator = () => {
    let timeline = document.getElementById("timeline");
    let left;
    // 1 Monat View
    if (
      timeline.getElementsByClassName("fc-resourceTimelineMonth-view").length >
      0
    ) {
      let width = timeline.getElementsByClassName("fc-timeline-slot-label")[1]
          .offsetWidth,
        todayDate = new Date().getDate(),
        lastDateOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate(),
        dayWidth = timeline.getElementsByClassName(
          "fc-timeline-slot fc-day-fri"
        )[0].offsetWidth,
        hoursSubtract = (dayWidth / 24) * (24 - new Date().getHours());
      left = (width / lastDateOfMonth) * todayDate - hoursSubtract + "px";
    }
    // 1 Jahr View
    if (
      timeline.getElementsByClassName("fc-resourceTimelineYear-view").length > 0
    ) {
      let width =
          timeline.getElementsByClassName("fc-timeline-slot")[1].offsetWidth,
        todayDate = new Date().getDate(),
        lastDateOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
      left = (width / lastDateOfMonth) * todayDate + "px";
    }
    // 3 Jahres View
    if (
      timeline.getElementsByClassName("fc-resourceTimeline3Years-view").length >
      0
    ) {
      let width =
          timeline.getElementsByClassName("fc-timeline-slot")[4].offsetWidth,
        todayDate = new Date().getDate(),
        lastDateOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
      left = (width / lastDateOfMonth) * todayDate + "px";
    }
    timeline.getElementsByClassName(
      "fc-timeline-now-indicator-container"
    )[0].style.left = left;
    timeline.getElementsByClassName(
      "fc-timeline-now-indicator-container"
    )[1].style.left = left;
  };

  const eventDidMount = () => {
    // if (args.event.extendedProps.status === "active-undiscontinued") {
    //   args.el.style.background =
    //     "linear-gradient(to left, transparent 0px, " +
    //     args.event.backgroundColor +
    //     " 100px)";
    // }
    let timeline = document.getElementById("timeline");
    let scrollWidth =
      timeline.getElementsByClassName("fc-scroller")[1].scrollWidth;
    let day = Math.floor(
      (new Date() -
        new Date(new Date().getFullYear(), 0, 0) +
        (new Date(new Date().getFullYear(), 0, 0).getTimezoneOffset() -
          new Date().getTimezoneOffset()) *
          60 *
          1000) /
        (1000 * 60 * 60 * 24)
    );
    timeline
      .getElementsByClassName("fc-scroller")[1]
      .scrollTo((scrollWidth / 365) * day - timeline.offsetWidth / 2, 0);
  };

  return (
    <Box
      id={"timeline"}
      className={"card"}
      sx={{
        width: "calc(100% - 2 * var(--listsPadding))",
      }}
    >
      <FullCalendar
        schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
        plugins={[interactionPlugin, resourceTimelinePlugin]}
        initialView="resourceTimelineYear"
        viewDidMount={timelineDidMount}
        eventDidMount={eventDidMount}
        nowIndicatorDidMount={updatePositionNowIndicator}
        initialDate={removeTime(new Date())}
        editable={false}
        headerToolbar={{
          // left: "resourceTimelineDay,resourceTimelineMonth,resourceTimelineYear",
          left: "resourceTimelineMonth,resourceTimelineYear,resourceTimeline3Years",
          center: "title",
          right: "today prev,next",
        }}
        height={247 + props.data.length * 17}
        slotLabelFormat={[
          { year: "numeric" },
          { month: "short" },
          { day: "numeric" },
        ]}
        slotDuration={"24:00"}
        nowIndicator={true}
        resourceAreaHeaderContent={t("dashboard_contractDuration")}
        resourceGroupField={"groupId"}
        eventOrder={"sortOrder"}
        resources={[
          { id: "monthly", groupId: t('duration_monthly'), title: " " },
          { id: "oneYear", groupId: t('duration_oneYear'), title: " " },
          { id: "twoYears", groupId: t('duration_twoYears'), title: " " },
        ]}
        resourceAreaWidth={"140px"}
        events={getAllEvents}
        locale={deLocale}
        views={{
          resourceTimelineMonth: {
            type: "resourceTimeline",
            duration: { months: 1 },
            buttonText: t("timeline_month"),
          },
          resourceTimelineYear: {
            type: "resourceTimeline",
            duration: { years: 1 },
            buttonText: t("timeline_year"),
            slotDuration: { months: 1 },
          },
          resourceTimeline3Years: {
            type: "resourceTimeline",
            duration: { years: 3 },
            buttonText: t("timeline_3years"),
          },
        }}
      />
    </Box>
  );
};

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.shape({
        title: PropTypes.string,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        color: PropTypes.string,
        vertragsdauer: PropTypes.string,
        vertragsStatus: PropTypes.string,
      }),
    })
  ),
};

export default Timeline;
