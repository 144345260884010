import React, { useState } from "react";
import {
  Button,
  Card,
  Dialog,
  IconButton,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Add,
  ArrowCircleDownOutlined,
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  ArrowCircleUpOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Edit,
} from "@mui/icons-material";
import {
  API_deleteTasks,
  API_updateSwimlane,
  API_updateTask,
  API_updateColumn,
  API_deleteColumns,
  API_deleteSwimlanes, API_addColumn, API_addSwimlane, API_addTask
} from "../../../services/backendService";
import { t } from "i18next";

function RolloutProcess(props) {
  const [hoveredCart, setHoveredCart] = useState(-1);
  const [hoveredSwimlane, setHoveredSwimlane] = useState(-1);
  const [hoveredColumn, setHoveredColumn] = useState(-1);
  const [hideTasks, setHideTasks] = useState([]);
  const [showUpdateElement, setShowUpdateElement] = useState(null);
  const [updateElementInput, setUpdateElementInput] = useState(null);
  const [updateElementTextarea, setUpdateElementTextarea] = useState(null);
  const [lockScreen, setLockScreen] = React.useState(false);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    setLockScreen(true);
    props.setLoading(true);
    let oldColumnId = result.source.droppableId.slice(
      0,
      result.source.droppableId.indexOf("_")
    );
    let newColumnId = result.destination.droppableId.slice(
      0,
      result.destination.droppableId.indexOf("_")
    );
    let oldSwimlaneId = result.source.droppableId.slice(
      result.source.droppableId.indexOf("_") + 1,
      result.source.droppableId.length
    );
    let newSwimlaneId = result.destination.droppableId.slice(
      result.destination.droppableId.indexOf("_") + 1,
      result.destination.droppableId.length
    );
    let newOrder = result.destination.index;
    let tasksCopy = JSON.parse(JSON.stringify(props.tasks));
    let sameList = result.source.droppableId === result.destination.droppableId;
    let movingElement = tasksCopy.filter(function (task) {
      return task.id === result.draggableId;
    })[0];
    if (sameList) {
      // verschobenes Element bleibt in seiner Liste
      let listOfElements = tasksCopy.filter(function (task) {
        return (
          task.column.id === newColumnId &&
          task.swimlane.id === newSwimlaneId &&
          task.id !== result.draggableId
        );
      });
      listOfElements.splice(newOrder, 0, movingElement);
      let taskRequests = [];
      for (let i = 0; i < listOfElements.length; i++) {
        taskRequests.push(API_updateTask({ order: i }, listOfElements[i].id));
      }
      Promise.all(taskRequests).then(() => {
        props.boardFullSync().then(function () {
          setLockScreen(false);
          props.setLoading(false);
        });
      });
    } else {
      // verschobenes Element aus alter Liste löschen
      let taskRequests = [];
      let sourceListOfElements = tasksCopy.filter(function (task) {
        return (
          task.column.id === oldColumnId &&
          task.swimlane.id === oldSwimlaneId &&
          task.id !== result.draggableId
        );
      });
      for (let i = 0; i < sourceListOfElements.length; i++) {
        taskRequests.push(
          API_updateTask({ order: i }, sourceListOfElements[i].id)
        );
      }
      // verschobenes Element in neue Liste schieben
      let destinationListOfElement = tasksCopy.filter(function (task) {
        return (
          task.column.id === newColumnId && task.swimlane.id === newSwimlaneId
        );
      });
      movingElement.column.id = newColumnId;
      movingElement.swimlane.id = newSwimlaneId;
      destinationListOfElement.splice(newOrder, 0, movingElement);
      for (let i = 0; i < destinationListOfElement.length; i++) {
        taskRequests.push(
          API_updateTask(
            {
              order: i,
              column: { id: newColumnId },
              swimlane: { id: newSwimlaneId },
            },
            destinationListOfElement[i].id
          )
        );
      }
      Promise.all(taskRequests).then(() => {
        props.boardFullSync().then(function () {
          setLockScreen(false);
          props.setLoading(false);
        });
      });
    }
    props.setTasks(
      tasksCopy.sort((a, b) => {
        return a.order - b.order;
      })
    );
    setLockScreen(false);
    props.setLoading(false);
  };

  const updateElementText = (data) => {
    props.setLoading(true);
    switch (data.key) {
      case "task": {
        let changedTask = props.tasks.filter((task) => {
          return data.id === task.id;
        })[0];
        setShowUpdateElement(null);
        API_updateTask(
          { name: updateElementInput, notes: updateElementTextarea },
          changedTask.id
        ).then(() => {
          props.boardFullSync().then(() => {
            props.setLoading(false);
          });
        });
        break;
      }
      case "column": {
        let changedColumn = props.columns.filter((column) => {
          return data.id === column.id;
        })[0];
        setShowUpdateElement(null);
        API_updateColumn({ name: updateElementInput }, changedColumn.id).then(
          () => {
            props.boardFullSync().then(() => {
              props.setLoading(false);
            });
          }
        );
        break;
      }
      case "swimlane": {
        let changedSwimlane = props.swimlanes.filter((swimlane) => {
          return data.id === swimlane.id;
        })[0];
        setShowUpdateElement(null);
        API_updateSwimlane(
          { name: updateElementInput },
          changedSwimlane.id
        ).then(() => {
          props.boardFullSync().then(() => {
            props.setLoading(false);
          });
        });
        break;
      }
      default:
        console.log("TODO", data);
    }
  };

  const getDialogHeader = () => {
    switch (showUpdateElement.key) {
      case "task":
        return t('rollout_changeTask');
      case "column":
        return t('rollout_changeColumn');
      case "swimlane":
        return t('rollout_changeSwimlane');
      case "addColumn":
          return t('rollout_newColumnName');
      case "addSwimlane":
        return t('rollout_newSwimlaneName');
      default:
        console.log("TODO", showUpdateElement.key);
        return "";
    }
  };

  const addTask = (columnId, swimlaneId) => {
    props.setLoading(true);
    let order = 0;
    for (let i = 0; i < props.tasks.length; i++) {
      if (
        props.tasks[i].column.id === columnId &&
        props.tasks[i].swimlane.id === swimlaneId
      ) {
        order++;
      }
    }
    API_addTask(
      `{
        name: "Aufgabe",
        board: {
          id: "` +
        props.boardId +
        `"
        },
        column: {
          id: "` +
        columnId +
        `"
        },
        swimlane: {
          id: "` +
        swimlaneId +
        `"
        },
        order: ` +
        order +
        `,
      }`
    ).then(() => props.boardFullSync().then(props.setLoading(false)));
  };

  const deleteTask = (data) => {
    props.setLoading(true);
    setShowUpdateElement(null);
    API_deleteTasks([data.id]).then(() => {
      let removedTask = props.tasks.filter((task) => {
        return task.id === data.id;
      })[0];
      let oldOrderTasks = props.tasks.filter((task) => {
        return (
          task.id !== data.id &&
          task.column.id === removedTask.column.id &&
          task.swimlane.id === removedTask.swimlane.id
        );
      });
      if (oldOrderTasks.length > 0) {
        let requests = [];
        for (let i = 0; i < oldOrderTasks.length; i++) {
          requests.push(API_updateTask({ order: i }, oldOrderTasks[i].id));
        }
        Promise.all(requests).then(() => {
          props.boardFullSync().then(props.setLoading(false));
        });
      } else {
        props.boardFullSync().then(props.setLoading(false));
      }
    });
  };

  const deleteColumn = (data) => {
    props.setLoading(true);
    setShowUpdateElement(null);
    API_deleteColumns([data.id]).then(() => {
      let oldOrderColumns = props.columns.filter((column) => {
        return data.id !== column.id;
      });
      if (oldOrderColumns.length > 0) {
        let requests = [];
        for (let i = 0; i < oldOrderColumns.length; i++) {
          requests.push(API_updateColumn({ order: i }, oldOrderColumns[i].id));
        }
        Promise.all(requests).then(() => {
          props.boardFullSync().then(props.setLoading(false));
        });
      } else {
        props.boardFullSync().then(props.setLoading(false));
      }
    });
  };

  const deleteSwimlane = (data) => {
    props.setLoading(true);
    setShowUpdateElement(null);
    API_deleteSwimlanes([data.id]).then(() => {
      let oldOrderSwimlanes = props.swimlanes.filter((swimlane) => {
        return data.id !== swimlane.id;
      });
      if (oldOrderSwimlanes.length > 0) {
        let requests = [];
        for (let i = 0; i < oldOrderSwimlanes.length; i++) {
          requests.push(
            API_updateSwimlane({ order: i }, oldOrderSwimlanes[i].id)
          );
        }
        Promise.all(requests).then(() => {
          props.boardFullSync().then(props.setLoading(false));
        });
      } else {
        props.boardFullSync().then(props.setLoading(false));
      }
    });
  };

  const changeOrder = (direction) => {
    props.setLoading(true);
    if (showUpdateElement.key === "swimlane") {
      let swimlanesCopy = JSON.parse(JSON.stringify(props.swimlanes));
      let movingElement = swimlanesCopy.filter(function (swimlane) {
        return swimlane.id === showUpdateElement.id;
      })[0];
      let listOfElements = swimlanesCopy.filter(function (swimlane) {
        return swimlane.id !== showUpdateElement.id;
      });
      let newOrder =
        direction === "up"
          ? showUpdateElement.order - 1
          : showUpdateElement.order + 1;
      listOfElements.splice(newOrder, 0, movingElement);
      let swimlaneRequests = [];
      for (let i = 0; i < listOfElements.length; i++) {
        swimlaneRequests.push(
          API_updateSwimlane({ order: i }, listOfElements[i].id)
        );
      }
      Promise.all(swimlaneRequests).then(() => {
        props.boardFullSync().then(function () {
          setLockScreen(false);
          props.setLoading(false);
          setShowUpdateElement(null);
        });
      });
    } else if (showUpdateElement.key === "column") {
      let colmunsCopy = JSON.parse(JSON.stringify(props.columns));
      let movingElement = colmunsCopy.filter(function (column) {
        return column.id === showUpdateElement.id;
      })[0];
      let listOfElements = colmunsCopy.filter(function (column) {
        return column.id !== showUpdateElement.id;
      });
      let newOrder =
        direction === "left"
          ? showUpdateElement.order - 1
          : showUpdateElement.order + 1;
      listOfElements.splice(newOrder, 0, movingElement);
      let columnRequests = [];
      for (let i = 0; i < listOfElements.length; i++) {
        columnRequests.push(
          API_updateColumn({ order: i }, listOfElements[i].id)
        );
      }
      Promise.all(columnRequests).then(() => {
        props.boardFullSync().then(function () {
          setLockScreen(false);
          props.setLoading(false);
          setShowUpdateElement(null);
        });
      });
    }
  };

  const addColumn = () => {
    props.setLoading(true);
    API_addColumn(
      `{
        name: "` +
        updateElementInput +
        `",
        board: {
          id: "` +
        props.boardId +
        `"
        },
        order: ` +
        props.columns.length +
        `,
      }`
    ).then(() =>
      props.boardFullSync().then(() => {
        setShowUpdateElement(null);
        props.setLoading(false);
      })
    );
  };

  const addSwimlane = () => {
    props.setLoading(true);
    API_addSwimlane(
      `{
        name: "` +
        updateElementInput +
        `",
        board: {
          id: "` +
        props.boardId +
        `"
        },
        order: ` +
        props.swimlanes.length +
        `,
      }`
    ).then(() =>
      props.boardFullSync().then(() => {
        setShowUpdateElement(null);
        props.setLoading(false);
      })
    );
  };

  return (
    <Box sx={{overflow: "scroll", maxWidth: "100%", maxHeight: "100%"}}>
      {lockScreen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: "transparent",
            opacity: 0.5,
            zIndex: 100,
          }}
        ></Box>
      )}
      {showUpdateElement && (
        <Dialog open={!!showUpdateElement} sx={{ p: "10px" }}>
          <Box sx={{ p: "20px" }}>
            <TextField
              id="outlined-basic"
              label={getDialogHeader()}
              variant="outlined"
              defaultValue={updateElementInput}
              onChange={(event) => setUpdateElementInput(event.target.value)}
            />
            {/*<TextArea label={'Notizen'} variant={outlined} defaultValue={updateElementTextarea} />*/}
            {showUpdateElement.key === "task" && (
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Notizen"
                style={{ width: 200 }}
                defaultValue={updateElementTextarea}
                onChange={(event) =>
                  setUpdateElementTextarea(event.target.value)
                }
              />
            )}
            <Box sx={{ display: "flex" }}>
              {/*column*/}
              {showUpdateElement.key === "column" && (
                <IconButton
                  disabled={showUpdateElement.order === 0}
                  onClick={() => changeOrder("left")}
                >
                  <ArrowCircleLeftOutlined sx={{ scale: "1.5" }} />
                </IconButton>
              )}
              {showUpdateElement.key === "column" && (
                <IconButton
                  disabled={
                    showUpdateElement.order === props.columns.length - 1
                  }
                  onClick={() => changeOrder("right")}
                >
                  <ArrowCircleRightOutlined sx={{ scale: "1.5" }} />
                </IconButton>
              )}
              {/*swimlane*/}
              {showUpdateElement.key === "swimlane" && (
                <IconButton
                  disabled={showUpdateElement.order === 0}
                  onClick={() => changeOrder("up")}
                >
                  <ArrowCircleUpOutlined sx={{ scale: "1.5" }} />
                </IconButton>
              )}
              {showUpdateElement.key === "swimlane" && (
                <IconButton
                  disabled={
                    showUpdateElement.order === props.swimlanes.length - 1
                  }
                  onClick={() => changeOrder("down")}
                >
                  <ArrowCircleDownOutlined sx={{ scale: "1.5" }} />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {showUpdateElement.key === "task" && (
                <Button
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7575" },
                  }}
                  onClick={() => deleteTask(showUpdateElement)}
                >
                  löschen
                </Button>
              )}
              {showUpdateElement.key === "column" && (
                <Button
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7575" },
                  }}
                  onClick={() => deleteColumn(showUpdateElement)}
                >
                  löschen
                </Button>
              )}
              {showUpdateElement.key === "swimlane" && (
                <Button
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7575" },
                  }}
                  onClick={() => deleteSwimlane(showUpdateElement)}
                >
                  löschen
                </Button>
              )}
              <Button
                sx={{
                  backgroundColor: "yellow",
                  color: "black",
                  "&:hover": { backgroundColor: "#fcfca4" },
                }}
                onClick={() => setShowUpdateElement(null)}
              >
                {t('cancel')}
              </Button>
              {(showUpdateElement.key === "task" ||
                showUpdateElement.key === "column" ||
                showUpdateElement.key === "swimlane") && (
                <Button
                  sx={{
                    backgroundColor: "green",
                    color: "white",
                    "&:hover": { backgroundColor: "#48b048" },
                  }}
                  onClick={() => updateElementText(showUpdateElement)}
                >
                  speichern
                </Button>
              )}
              {showUpdateElement.key === "addColumn" && (
                <Button
                  sx={{
                    backgroundColor: "green",
                    color: "white",
                    "&:hover": { backgroundColor: "#48b048" },
                  }}
                  onClick={() => addColumn()}
                >
                  {t('add')}
                </Button>
              )}
              {showUpdateElement.key === "addSwimlane" && (
                <Button
                  sx={{
                    backgroundColor: "green",
                    color: "white",
                    "&:hover": { backgroundColor: "#48b048" },
                  }}
                  onClick={() => addSwimlane()}
                >
                  {t('add')}
                </Button>
              )}
            </Box>
          </Box>
        </Dialog>
      )}
      <Box
        sx={{
          // overflowX: "scroll",
          // overflowY: "hidden",
          p: "var(--kanbanPadding)",
        }}
      >
        <Box sx={{ display: "flex", position: "relative", minHeight: "40px" }}>
          {props.columns.map(function (column, i) {
            return (
              <Box
                key={i}
                sx={{
                  width: "var(--kanbanWidth)",
                  display: "flex",
                  m:
                    i === props.columns
                      ? "var(--kanbanPadding) 0 var(--kanbanPadding) var(--kanbanPadding)"
                      : "var(--kanbanPadding) var(--kanbanPadding) var(--kanbanPadding) 0",
                  minWidth: "var(--kanbanWidth)",
                  maxWidth: "var(--kanbanWidth)",
                }}
                onMouseLeave={() => setHoveredColumn(-1)}
                onMouseEnter={() => setHoveredColumn(column.id)}
              >
                <Box
                  sx={{
                    p: "var(--kanbanPadding)",
                    position: "relative",
                    height: "20px",
                  }}
                >
                  {column.name}
                  <Edit
                    sx={{
                      display: hoveredColumn === column.id ? "block" : "none",
                      cursor: "pointer",
                      position: "absolute",
                      right: "-15px",
                      top: "5px",
                    }}
                    onClick={() => {
                      setUpdateElementInput(column.name);
                      setShowUpdateElement({
                        key: "column",
                        id: column.id,
                        value: column.name,
                        order: column.order,
                      });
                    }}
                  />
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              display: "flex",
              position: "relative",
              top: "20px",
              right: props.columns.length > 0 ? 0 : "",
              cursor: "pointer",
            }}
            onClick={() => {
              setUpdateElementInput("");
              setShowUpdateElement({ key: "addColumn" });
            }}
          >
            <Add style={{ color: "#5bcebf" }} />
            {props.columns.length === 0 && t('rollout_addColumn')}
          </Box>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          {props.swimlanes.map(function (swimlane, i) {
            return (
              <Box key={i}>
                <Box
                  sx={{
                    backgroundColor: "var(--elementBackgroundColor)",
                    borderRadius: "var(--listsPadding)",
                    boxShadow: "5px 5px 5px #dce3eb",
                    display: "flex",
                    width:
                      "calc(var(--kanbanWidth) * " +
                      props.columns.length +
                      " + var(--kanbanPadding) * " +
                      (props.columns.length - 1) +
                      ")",
                  }}
                  onMouseLeave={() => setHoveredSwimlane(-1)}
                  onMouseEnter={() => setHoveredSwimlane(swimlane.id)}
                >
                  <Box sx={{ p: "10px", position: "relative" }}>
                    {!hideTasks.includes(swimlane.id) && (
                      <ArrowDropDownOutlined
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setHideTasks(hideTasks.concat([swimlane.id]))
                        }
                      />
                    )}
                    {hideTasks.includes(swimlane.id) && (
                      <ArrowDropUpOutlined
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setHideTasks(
                            hideTasks.filter(function (value) {
                              return value !== swimlane.id;
                            })
                          )
                        }
                      />
                    )}
                    {swimlane.name}
                    <Edit
                      sx={{
                        display:
                          hoveredSwimlane === swimlane.id ? "block" : "none",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-15px",
                        top: "5px",
                      }}
                      onClick={() => {
                        setUpdateElementInput(swimlane.name);
                        setShowUpdateElement({
                          key: "swimlane",
                          id: swimlane.id,
                          value: swimlane.name,
                          order: swimlane.order,
                        });
                      }}
                    />
                  </Box>
                </Box>
                {!hideTasks.includes(swimlane.id) && (
                  <Box sx={{ display: "flex" }}>
                    {props.columns.map((column, i) => (
                      <Droppable
                        key={column.id + "_" + swimlane.id}
                        droppableId={column.id + "_" + swimlane.id}
                      >
                        {(provided) => (
                          <Box
                            sx={{
                              backgroundColor: "var(--elementBackgroundColor)",
                              minHeight: "50px",
                              margin:
                                i === props.columns
                                  ? "var(--kanbanPadding) 0 var(--kanbanPadding) var(--kanbanPadding)"
                                  : "var(--kanbanPadding) var(--kanbanPadding) var(--kanbanPadding) 0",
                              width:
                                "calc(var(--kanbanWidth) - 2*var(--kanbanPadding))",
                              p: "var(--kanbanPadding)",
                              borderRadius: "var(--listsPadding)",
                            }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Box
                              sx={{
                                textAlign: "center",
                                cursor: "pointer",
                                width:
                                  "calc(var(--kanbanWidth) - 2*var(--kanbanPadding))",
                              }}
                              onClick={() => addTask(column.id, swimlane.id)}
                            >
                              <Add style={{ color: "#5bcebf" }} /> Aufgabe
                            </Box>
                            {props.tasks
                              .filter(function (task) {
                                if (!!task.column && !!task.swimlane) {
                                  return (
                                    task.column.id === column.id &&
                                    task.swimlane.id === swimlane.id
                                  );
                                }
                              })
                              .map((task, i) => (
                                <Draggable
                                  key={task.id}
                                  draggableId={task.id}
                                  index={i}
                                >
                                  {(provided) => (
                                    <Card
                                      sx={{
                                        position: "relative",
                                        minHeight: "20px",
                                        mt: "10px",
                                        width:
                                          "calc(var(--kanbanWidth) - 4*var(--kanbanPadding))",
                                        p: "var(--kanbanPadding)",
                                      }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onMouseLeave={() => setHoveredCart(-1)}
                                      onMouseEnter={() =>
                                        setHoveredCart(task.id)
                                      }
                                    >
                                      {task.name}
                                      <Edit
                                        sx={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                          display:
                                            hoveredCart === task.id
                                              ? "block"
                                              : "none",
                                        }}
                                        onClick={() => {
                                          setUpdateElementInput(task.name);
                                          setUpdateElementTextarea(task.notes);
                                          setShowUpdateElement({
                                            key: "task",
                                            id: task.id,
                                          });
                                        }}
                                      />
                                    </Card>
                                  )}
                                </Draggable>
                              ))}
                          </Box>
                        )}
                      </Droppable>
                    ))}
                  </Box>
                )}
              </Box>
            );
          })}
          {props.columns.length > 0 && (
            <Box
              sx={{
                backgroundColor: "var(--elementBackgroundColor)",
                borderRadius: "var(--listsPadding)",
                boxShadow: "5px 5px 5px #dce3eb",
                display: "flex",
                cursor: "pointer",
                p: "10px",
                width: "calc(var(--kanbanWidth)* " + props.columns.length + ")",
              }}
              onClick={() => {
                setUpdateElementInput("");
                setShowUpdateElement({ key: "addSwimlane" });
              }}
            >
              <Add style={{ color: "#5bcebf" }} />
              Swimlane hinzufügen
            </Box>
          )}
        </DragDropContext>
      </Box>
      {/*<Button*/}
      {/*  onClick={() => {*/}
      {/*    props.setLoading(true);*/}
      {/*    templateRollout(*/}
      {/*      props.boardId,*/}
      {/*      props.boardFullSync,*/}
      {/*      props.setLoading*/}
      {/*    ).then();*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Template hinzufügen*/}
      {/*</Button>*/}
    </Box>
  );
}

export default RolloutProcess;

RolloutProcess.propTypes = {
  selectedTool: PropTypes.string,
  boardFullSync: PropTypes.func,
  boardId: PropTypes.string,
  setLoading: PropTypes.func,
  columns: PropTypes.array,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.shape({
        id: PropTypes.string,
      }),
      swimlane: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ),
  swimlanes: PropTypes.array,
  setTasks: PropTypes.func,
};
