import React, { useState } from "react";
import { Avatar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { AccountCircle, AddBoxOutlined, DeleteOutlined } from "@mui/icons-material";
import {
  API_addEmployeeToolActivated,
  API_addEmployeeToolResponsibility,
  API_removeEmployeeToolActivated,
  API_removeEmployeeToolResponsibility,
} from "../../../services/backendService";
import Notification from "../../notification";
import ItemSearchList from "./itemSearchList";
import {
  getCssValue,
  handleImageLoaded,
  updateToolsAndEmployees,
} from "../../../services/utils";
import { t } from "i18next";

const OwnerAndActivated = (props) => {
  const [hoveredEmployee, setHoveredEmployee] = useState(null);
  const [showList, setShowList] = useState(undefined);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const keys = ["activated", "responsibility"];

  const addEmployee = (employeeId, category) => {
    props.setLoading(true);
    if (category === "activated") {
      API_addEmployeeToolActivated(
        employeeId,
        props.selectedTool.id
      ).then(() => {
        updateToolsAndEmployees(
          props.setEmployees,
          props.setTools,
          props.setLoading,
          props.selectedTool.id,
          props.setSelectedTool
        );
        setShowList(undefined);
      });
    } else {
      API_addEmployeeToolResponsibility(
        employeeId,
        props.selectedTool.id
      ).then(() => {
        updateToolsAndEmployees(
          props.setEmployees,
          props.setTools,
          props.setLoading,
          props.selectedTool.id,
          props.setSelectedTool
        );
        setShowList(undefined);
      });
    }
  };

  const removeEmployee = (category, id) => {
    props.setLoading(true);
    if (category === "activated") {
      API_removeEmployeeToolActivated(
        id,
        props.selectedTool.id
      ).then(() => {
        updateToolsAndEmployees(
          props.setEmployees,
          props.setTools,
          props.setLoading,
          props.selectedTool.id,
          props.setSelectedTool
        );
      });
    } else {
      API_removeEmployeeToolResponsibility(
        id,
        props.selectedTool.id
      ).then(() => {
        updateToolsAndEmployees(
          props.setEmployees,
          props.setTools,
          props.setLoading,
          props.selectedTool.id,
          props.setSelectedTool
        );
      });
    }
  };

  return (
    <Box id={"userBoxAtTools"}>
      <Box id={"ownerAndActivatedBoxTop"}></Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", m: "0 var(--detailsBoxPadding)" }}
      >
        {keys.map((x) => {
          return (
            <Box
              className={"card"}
              id={"ownerAndActivatedBoxTop"}
              key={x}
              sx={{
                flexGrow: 1,
                position: "relative",
                margin: x === "activated" ? "0 var(--detailsBoxPadding) 0 0" : "0",
                p: "0 var(--detailsBoxPadding)"
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <Typography
                  variant={"h5"}
                  sx={{ lineHeight: "var(--lineHeight)" }}
                >
                  {x === "responsibility"
                    ? t('employee_toolResponsibility')
                    : t('toolsServices_employeeActivated')}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <AddBoxOutlined
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      zIndex: 2,
                      mr: "calc(2*var(--detailsBoxPadding))",
                    }}
                    onClick={() =>
                      showList === undefined
                        ? setShowList(x)
                        : setShowList(undefined)
                    }
                  />
                </Box>
              </Box>
              <List
                disablePadding
                sx={{minHeight: "100%"}}
              >
                {props[x].map((employee) => {
                  return (
                    <ListItem disablePadding
                              key={x + "-" + employee.id}
                              sx={{
                                p: "var(--detailsBoxPadding)",
                                maxHeight: "64px"
                              }}
                    >
                      <ListItemButton
                        onMouseEnter={() => setHoveredEmployee(employee.id)}
                        onMouseLeave={() => setHoveredEmployee(null)}
                      >
                        <ListItemIcon>
                          {employee.avatar ? (
                            <Avatar
                              key={employee.email}
                              alt={employee.firstname + " " + employee.lastname}
                              src={"assets/loading.svg"}
                              onLoad={(event) =>
                                handleImageLoaded(
                                  event,
                                  employee.avatar,
                                  "?height=" +
                                  (getCssValue("--lineHeight") -
                                    2 * getCssValue("--detailsBoxPadding"))
                                )
                              }
                              sx={{
                                height:
                                  "calc(var(--lineHeight) - 2*var(--detailsBoxPadding))",
                                width:
                                  "calc(var(--lineHeight) - 2*var(--detailsBoxPadding))",
                              }}
                            />
                          ) : (
                            <AccountCircle
                              sx={{
                                fontSize:
                                  "calc(var(--lineHeight) - 2*var(--detailsBoxPadding))",
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            (employee.firstname ? employee.firstname + " " : "") +
                            (employee.lastname ? employee.lastname : "")
                          }
                        />
                        {hoveredEmployee === employee.id && <DeleteOutlined
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            removeEmployee(x, employee.employee_tool_id)
                          }
                        />}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
                </List>
              {showList !== undefined && (
                <ItemSearchList
                  allItems={props.employees
                    .map(function (item) {
                      return {
                        id: item.id,
                        logo: {
                          id: item.avatar ? item.avatar.id : null,
                        },
                        name:
                          (item.firstname ? item.firstname + " " : "") +
                          (item.lastname ? item.lastname : ""),
                      };
                    })
                    .filter(function (itemToFilter) {
                      for (let i = 0; i < props[showList].length; i++) {
                        if (itemToFilter.id === props[showList][i].id) {
                          return false;
                        }
                      }
                      return true;
                    })}
                  setNotify={setNotify}
                  addElement={addEmployee}
                  closeList={() => setShowList(undefined)}
                  toolCategory={showList}
                />
              )}
            </Box>
          );
        })}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

OwnerAndActivated.propTypes = {
  activated: PropTypes.arrayOf(
    PropTypes.shape({
      employee_tool_id: PropTypes.string,
      avatar: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  employees: PropTypes.array,
  toolId: PropTypes.string,
  setNotify: PropTypes.func,
  setTools: PropTypes.func,
  setLoading: PropTypes.func,
  setEmployees: PropTypes.func,
};

export default OwnerAndActivated;
