import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import Tabs from "./components/tabs";
import Matrix from "./components/matrix";

const Index = (props) => {
  const [activeTab, setActiveTab] = React.useState("all");

  return (
    <Box sx={{ maxWidth: "100%", overflow: "auto", m: "0 var(--detailsBoxPadding)" }}>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Matrix employees={props.employees}
              tools={props.tools.filter(function (tool) {
                switch (activeTab) {
                  case "active":
                    if (tool.status !== "inTesting") {
                      return tool
                    }
                    break
                  case "inTest":
                    if (tool.status === "inTesting") {
                      return tool
                    }
                    break
                  default:
                    return tool
                }
              })}
              setLoading={props.setLoading}
              setEmployees={props.setEmployees}
              setTools={props.setTools}
      />
    </Box>
  );
};

Index.propTypes = {
  employees: PropTypes.array,
  tools: PropTypes.array,
  setLoading: PropTypes.func,
  setTools: PropTypes.func,
  setEmployees: PropTypes.func,
};

export default Index;
