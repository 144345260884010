import React from "react";
import PropTypes from "prop-types";
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import RightPlusButton from "../../rightPlusButton";
import { t } from "i18next";
import { Box } from "@mui/system";
import "../css/employees.css";
import DynamicTextButton from "../../common/dynamicTextButton";

function EmployeesHeader(props) {

  return (
    <>
      <Box
        padding={0}
        sx={{
          height: "var(--lineHeight)",
          display: "flex",
          alignItems: "center",
          pl: "var(--listsPadding)",
        }}
      >
        <Box sx={{width: { xs: "100%", sm: "20%" }, display: "flex", justifyContent: "space-between", height: "40px"}}
             id={"boxWithHeaderAndAddButton"}
        >
          {/*<ArrowBack onClick={() => props.handleArrowBackClick()} />*/}
          <Typography variant={"h5"} sx={{pl: "var(--listsPadding)"}}>{t("employees_header")}</Typography>
          <DynamicTextButton
            functions={() => {
              props.setFilterTypeEmployment("");
              props.setFilterTeam("");
              props.setFilterDepartment("");
              props.addEmployee();
            }}
          />
        </Box>
        <Box sx={{ml: "var(--listsPadding)", display: "flex"}}>
          <Button variant={props.filterDepartment === "" && props.filterTeam === "" && props.filterTypeEmployment === "" ? "contained" : "outlined"}
                  size={"small"}
                  onClick={() => {
                    props.setFilterDepartment("");
                    props.setFilterTeam("");
                    props.setFilterTypeEmployment("");
                  }
                                    }
          >{t('employees_all')}</Button>
          {/*Abteilung*/}
          <FormControl variant="outlined"
                       size="small"
                       sx={{ minWidth: 200, ml: "var(--detailsBoxPadding)" }}>
            <InputLabel id="filterDepartment-label">{t("employee_department")}</InputLabel>
            <Select
              labelId="filterDepartment-label"
              value={props.filterDepartment}
              onChange={(event) => {
                props.setFilterDepartment(event.target.value);
              }}
              label={t("employee_department")}
            >
              <MenuItem value=""><em>{t('employee_noDepartment')}</em></MenuItem>
              <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
              <MenuItem value={"Finance"}>Finance</MenuItem>
              <MenuItem value={"HR"}>HR</MenuItem>
              <MenuItem value={"IT"}>IT</MenuItem>
              <MenuItem value={"Management"}>Management</MenuItem>
            </Select>
          </FormControl>
          {/*Team*/}
          <FormControl variant="outlined"
                       size="small"
                       sx={{ minWidth: 200, ml: "var(--detailsBoxPadding)" }}>
            <InputLabel id="filterTeam-label">{t("employee_team")}</InputLabel>
            <Select
              labelId="filterTeam-label"
              value={props.filterTeam}
              onChange={(event) => {
                props.setFilterTeam(event.target.value);
              }}
              label={t("employee_department")}
            >
              <MenuItem value=""><em>{t('employee_noTeam')}</em></MenuItem>

              <MenuItem value={"Accounting"}>Accounting</MenuItem>
              <MenuItem value={"Controlling"}>Controlling</MenuItem>
              <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
              <MenuItem value={"Development"}>Development</MenuItem>
              <MenuItem value={"HR Management"}>HR Management</MenuItem>
              <MenuItem value={"IT Services"}>IT Services</MenuItem>
              <MenuItem value={"Management"}>Management</MenuItem>
              <MenuItem value={"Marketing"}>Marketing</MenuItem>
              <MenuItem value={"Product"}>Product</MenuItem>
              <MenuItem value={"Recruiting"}>Recruiting</MenuItem>
              <MenuItem value={"Sales"}>Sales</MenuItem>
            </Select>
          </FormControl>
          {/*Team*/}
          <FormControl variant="outlined"
                       size="small"
                       sx={{ minWidth: 200, ml: "var(--detailsBoxPadding)" }}>
            <InputLabel id="filterEmployment-label">{t("employee_typeEmployment")}</InputLabel>
            <Select
              labelId="filterEmployment-label"
              value={props.filterTypeEmployment}
              onChange={(event) => {
                props.setFilterTypeEmployment(event.target.value);
              }}
              label={t("employee_typeEmployment")}
            >
              <MenuItem value=""><em>{t('employee_noTeam')}</em></MenuItem>
              <MenuItem value={"Permanent employment"}>{t("employee_typeEmployment_permanent")}</MenuItem>
              <MenuItem value={"Working student"}>{t("employee_typeEmployment_student")}</MenuItem>
              <MenuItem value={"Freelance"}>{t("employee_typeEmployment_freelance")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <RightPlusButton plusFunction={props.addEmployee} />
    </>
  );
}

EmployeesHeader.propTypes = {
  minWidth600: PropTypes.bool,
  showDetails: PropTypes.bool,
  handleArrowBackClick: PropTypes.func,
  addEmployee: PropTypes.func,
};

export default EmployeesHeader;
