import React from "react";
import PropTypes from "prop-types";
import { Add } from "@mui/icons-material";

function RightPlusButton(props) {
  return (
    <Add
      sx={{
        position: "fixed",
        bottom: "var(--detailsBoxPadding)",
        right: "var(--detailsBoxPadding)",
        backgroundColor: "primary.main",
        zIndex: 10,
        borderRadius: "calc(2*var(--listsPadding))",
        height: "calc(var(--lineHeight) / 2)",
        width: "calc(var(--lineHeight) / 2)",
        cursor: "pointer",
        p: "calc(var(--detailsBoxPadding) / 2)",
        color: "primary.contrastText",
      }}
      onClick={() => props.plusFunction()}
    />
  );
}

RightPlusButton.propTypes = {
  plusFunction: PropTypes.func,
};

export default RightPlusButton;
