import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  BarController,
  Tooltip,
} from "chart.js";

import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { format } from "date-fns";

const ChartWrapper = styled.div`
  height: 512px;
  width: 100%;
`;

const PaymentBarChart = (props) => {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    BarController,
    Tooltip
  );

  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderRadius: 4
      },
    ],
  };
  if (!!props.data && props.data.length) {
    for (let i = 0; i < props.data.length; i++) {
      const toolsData = props.data[i];
      data.datasets[0].data.push({
        id: format(new Date(toolsData.payment_date), "dd.MM.yy"),
        nested: { value: toolsData.payment_amount },
        title: toolsData.tool_synced.name,
      });
      data.datasets[0].backgroundColor.push(
        toolsData.tool_synced.category.color
      );
    }
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].dataset.data[context[0].dataIndex].title;
          },
          label: function (context) {
            return (
              context.dataset.data[
                context.dataIndex
              ].nested.value.toLocaleString() + " €"
            );
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          maxRotation: 0,
        },
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
        offset: true,
      },
    },
    parsing: {
      xAxisKey: "id",
      yAxisKey: "nested.value",
    },
  };

  return (
    <Box
      className={"card"}
    >
      <ChartWrapper>
        <Chart type="bar" data={data} options={options} />
      </ChartWrapper>
    </Box>
  );
};

PaymentBarChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      payment_date: PropTypes.string,
      payment_amount: PropTypes.number,
      tool_synced: PropTypes.shape({
        category: PropTypes.object,
      }),
    })
  ),
};

export default withTheme(PaymentBarChart);
