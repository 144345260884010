import React, { useState } from "react";
import { TextField, MenuItem, Button } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { AccountBox } from "@mui/icons-material";
import { getCssValue, handleImageLoaded } from "../../../services/utils";
import {
  API_removeFile,
  API_uploadFile,
} from "../../../services/backendService";
import { t } from "i18next";

function EmployeeDetails(props) {
  const [showRemoveEmployeeButton, setShowRemoveEmployeeButton] =
    React.useState(false);
  const [firstname, setFirstname] = useState(
    props.employee.firstname !== null ? props.employee.firstname : ""
  );
  const [lastname, setLastname] = useState(
    props.employee.lastname !== null ? props.employee.lastname : ""
  );
  const [email, setEmail] = useState(
    props.employee.email !== null ? props.employee.email : ""
  );
  const [location, setLocation] = useState(
    props.employee.location !== null ? props.employee.location : "undefined"
  );
  const [team, setTeam] = useState(
    props.employee.team !== null ? props.employee.team : "undefined"
  );
  const [position, setPosition] = useState(
    props.employee.position !== null ? props.employee.position : "undefined"
  );
  const [department, setDepartment] = useState(
    props.employee.department !== null ? props.employee.department : "undefined"
  );
  const [employmentType, setEmploymentType] = useState(
    props.employee.type_of_employment !== null
      ? props.employee.type_of_employment
      : "undefined"
  );
  const [status, setStatus] = useState(
    props.employee.status !== null ? props.employee.status : "undefined"
  );

  React.useEffect(() => {
    if (document.getElementById("avatar")) {
      document.getElementById("avatar").src = "assets/loading.svg";
    }
    setFirstname(
      props.employee.firstname !== null ? props.employee.firstname : ""
    );
    setLastname(
      props.employee.lastname !== null ? props.employee.lastname : ""
    );
    setEmail(props.employee.email !== null ? props.employee.email : "");
    setLocation(
      props.employee.location !== null ? props.employee.location : "undefined"
    );
    setTeam(props.employee.team !== null ? props.employee.team : "undefined");
    setPosition(
      props.employee.position !== null ? props.employee.position : ""
    );
    setDepartment(
      props.employee.department !== null
        ? props.employee.department
        : "undefined"
    );
    setEmploymentType(
      props.employee.type_of_employment !== null
        ? props.employee.type_of_employment
        : "undefined"
    );
    setStatus(
      props.employee.status !== null ? props.employee.status : "undefined"
    );
    setShowRemoveEmployeeButton(false);
  }, [props.employee]);

  const handleFileUpload = (event) => {
    props.setLoading(true);
    API_uploadFile(
      event.target.files[0],
      "98fd9640-4ff4-403a-8758-3ca90ea445d9"
    ).then((avatarNewId) => {
      props.update('{avatar: {id: "' + avatarNewId + '"}}');
      if (props.employee.avatar) {
        API_removeFile(props.employee.avatar.id).then();
      }
    });
  };

  function getStatusColor() {
    switch (status) {
      case "Active":
        return "#4AC404";
      case "Inactive":
        return "#FC3240";
      case "Timeout":
        return "#FDA609";
      default:
        return "white";
    }
  }

  return (
    <>
      {!!props.employee && (
        <Box
          className={"card"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            m: "0 var(--detailsBoxPadding)",
            p: "var(--detailsBoxPadding) var(--detailsBoxPadding) 0 0",
          }}
        >
          <Box
            sx={{
              width: "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
              p: "0 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
            }}
          >
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleFileUpload}
              id={"uploadFileInput"}
            />
            <label htmlFor={"uploadFileInput"}>
              {props.employee.avatar ? (
                <Box
                  id={"avatar"}
                  component="img"
                  sx={{
                    height:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    width:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    cursor: "pointer",
                    borderRadius: "6px",
                  }}
                  alt={props.employee.firstname + " " + props.employee.lastname}
                  src={"assets/loading.svg"}
                  onLoad={(event) =>
                    handleImageLoaded(
                      event,
                      props.employee.avatar.id,
                      "?width=" +
                      (getCssValue("--detailsBoxWidth") -
                        getCssValue("--detailsBoxPadding"))
                    )
                  }
                ></Box>
              ) : (
                <AccountBox
                  sx={{
                    height:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    width:
                      "calc(var(--detailsBoxWidth) - var(--detailsBoxPadding))",
                    fontSize: "var(--detailsBoxWidth)",
                    cursor: "pointer",
                  }}
                />
              )}
            </label>
          </Box>
          <Box
            sx={{
              width:
                "calc(2*var(--detailsBoxWidth) + var(--detailsBoxPadding))",
              p: "0 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
            }}
          >
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) 0",
              }}
              id="firstname"
              label={t("employee_firstname")}
              type={"text"}
              value={firstname}
              onChange={(event) => setFirstname(event.target.value)}
              onBlur={(event) =>
                props.update('{ firstname: "' + event.target.value + '" }')
              }
            />
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
              }}
              id="lastname"
              label={t("employee_lastname")}
              type={"text"}
              value={lastname}
              onChange={(event) => setLastname(event.target.value)}
              onBlur={(event) =>
                props.update('{ lastname: "' + event.target.value + '" }')
              }
            />
            <br />
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) 0",
              }}
              id="email"
              label={t("employee_email")}
              type={"text"}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={(event) =>
                props.update('{ email: "' + event.target.value + '" }')
              }
            />
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
              }}
              id="location"
              label={t("employee_location")}
              type={"text"}
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              onBlur={(event) =>
                props.update('{ location: "' + event.target.value + '" }')
              }
            />
            <TextField
              sx={{
                color: "white",
                borderRadius: "var(--listsPadding)",
                textAlign: "center",
                mt: "var(--detailsBoxPadding)",
                width: "var(--detailsBoxWidth)",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: getStatusColor(),
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: getStatusColor(),
                  borderRadius: "var(--listsPadding)",
                  color: "white"
                },
                "& .MuiSelect-select": {
                  p: "7px !important",
                },
                "& .MuiSvgIcon-root": {
                  display: "none",
                },
              }}
              value={status}
              onChange={(event) => {
                setStatus(event.target.value);
                props.update('{ status: "' + event.target.value + '" }');
              }}
              select // tell TextField to render select
            >
              <MenuItem value={"Active"}>
                {t("employee_status_active")}
              </MenuItem>
              <MenuItem value={"Inactive"}>
                {t("employee_status_inactive")}
              </MenuItem>
              <MenuItem value={"Timeout"}>
                {t("employee_status_timeout")}
              </MenuItem>
              <MenuItem
                value={"undefined"}
                sx={{ display: "none" }}
                disabled={true}
              >
                {t("select")}
              </MenuItem>
            </TextField>
            {/*<TextField*/}
            {/*  sx={{*/}
            {/*    width: "var(--detailsBoxWidth)",*/}
            {/*    m: "5px 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",*/}
            {/*  }}*/}
            {/*  value={location}*/}
            {/*  onChange={(event) => {*/}
            {/*    setLocation(event.target.value);*/}
            {/*    props.update('{ location: "' + event.target.value + '" }');*/}
            {/*  }}*/}
            {/*  select // tell TextField to render select*/}
            {/*  label={t("employee_location")}*/}
            {/*>*/}
            {/*  <MenuItem value={"Bremen"}>Bremen</MenuItem>*/}
            {/*  <MenuItem value={"Dortmund"}>Dortmund</MenuItem>*/}
            {/*  <MenuItem value={"Hamburg"}>Hamburg</MenuItem>*/}
            {/*  <MenuItem value={"Jena"}>Jena</MenuItem>*/}
            {/*  <MenuItem value={"Köln"}>Köln</MenuItem>*/}
            {/*  <MenuItem value={"Nürnberg"}>Nürnberg</MenuItem>*/}
            {/*  <MenuItem value={"Olpe"}>Olpe</MenuItem>*/}
            {/*  <MenuItem value={"Remote"}>Remote</MenuItem>*/}
            {/*  <MenuItem*/}
            {/*    value={"undefined"}*/}
            {/*    sx={{ display: "none" }}*/}
            {/*    disabled={true}*/}
            {/*  >*/}
            {/*    {t("select")}*/}
            {/*  </MenuItem>*/}
            {/*</TextField>*/}
          </Box>
          <Box
            sx={{
              width:
                "calc(2*var(--detailsBoxWidth) + var(--detailsBoxPadding))",
              p: "0 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
            }}
          >
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) 0",
              }}
              value={employmentType}
              onChange={(event) => {
                setEmploymentType(event.target.value);
                props.update(
                  '{ type_of_employment: "' + event.target.value + '" }'
                );
              }}
              select // tell TextField to render select
              label={t("employee_typeEmployment")}
            >
              <MenuItem value={"Permanent employment"}>
                {t("employee_typeEmployment_permanent")}
              </MenuItem>
              <MenuItem value={"Working student"}>
                {t("employee_typeEmployment_student")}
              </MenuItem>
              <MenuItem value={"Freelance"}>
                {t("employee_typeEmployment_freelance")}
              </MenuItem>
              <MenuItem
                value={"undefined"}
                sx={{ display: "none" }}
                disabled={true}
              >
                {t("select")}
              </MenuItem>
            </TextField>
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
              }}
              id="position"
              label={t("employee_position")}
              type={"text"}
              value={position}
              onChange={(event) => setPosition(event.target.value)}
              onBlur={(event) =>
                props.update('{ position: "' + event.target.value + '" }')
              }
            />
            <br />
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) 0",
              }}
              value={department}
              onChange={(event) => {
                setDepartment(event.target.value);
                props.update('{ department: "' + event.target.value + '" }');
              }}
              select // tell TextField to render select
              label={t("employee_department")}
            >
              <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
              <MenuItem value={"Finance"}>Finance</MenuItem>
              <MenuItem value={"HR"}>HR</MenuItem>
              <MenuItem value={"IT"}>IT</MenuItem>
              <MenuItem value={"Management"}>Management</MenuItem>
              <MenuItem value={"Marketing and Sales"}>Marketing and Sales</MenuItem>
              <MenuItem
                value={"undefined"}
                sx={{ display: "none" }}
                disabled={true}
              >
                {t("select")}
              </MenuItem>
            </TextField>
            <TextField
              sx={{
                width: "var(--detailsBoxWidth)",
                m: "5px 0 var(--detailsBoxPadding) var(--detailsBoxPadding)",
              }}
              value={team}
              onChange={(event) => {
                setTeam(event.target.value);
                props.update('{ team: "' + event.target.value + '" }');
              }}
              select // tell TextField to render select
              label={t("employee_team")}
            >
              <MenuItem value={"Accounting"}>Accounting</MenuItem>
              <MenuItem value={"Controlling"}>Controlling</MenuItem>
              <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
              <MenuItem value={"Development"}>Development</MenuItem>
              <MenuItem value={"HR Management"}>HR Management</MenuItem>
              <MenuItem value={"IT Services"}>IT Services</MenuItem>
              <MenuItem value={"Management"}>Management</MenuItem>
              <MenuItem value={"Marketing"}>Marketing</MenuItem>
              <MenuItem value={"Product"}>Product</MenuItem>
              <MenuItem value={"Recruiting"}>Recruiting</MenuItem>
              <MenuItem value={"Sales"}>Sales</MenuItem>
              <MenuItem
                value={"undefined"}
                sx={{ display: "none" }}
                disabled={true}
              >
                {t("select")}
              </MenuItem>
            </TextField>
            {!showRemoveEmployeeButton && (
              <Button
                variant={"outlined"}
                sx={{
                  m: "var(--detailsBoxPadding) 0 0 245px",
                  width:
                    "var(--detailsBoxWidth)",
                }}
                onClick={() => setShowRemoveEmployeeButton(true)}
              >
                {t("delete")}
              </Button>
            )}
            {showRemoveEmployeeButton && (
              <Box sx={{ml: "245px"}}>
                {t("delete_sure")} <br/>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: "5px" }}>
                  <Button sx={{width: "calc(var(--detailsBoxWidth) / 2 - var(--detailsBoxPadding) / 2)"}}
                          onClick={() => props.delete()}
                          variant={"contained"}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    sx={{width: "calc(var(--detailsBoxWidth) / 2 - var(--detailsBoxPadding) / 2)"}}
                    onClick={() => setShowRemoveEmployeeButton(false)}
                    variant={"outlined"}
                  >
                    {t("no")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

EmployeeDetails.propTypes = {
  employee: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.string,
    team: PropTypes.string,
    position: PropTypes.string,
    department: PropTypes.string,
    type_of_employment: PropTypes.string,
    avatar: PropTypes.shape({
      id: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  update: PropTypes.func,
  delete: PropTypes.func,
  detailsWidth: PropTypes.string,
  leftSpace: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default EmployeeDetails;
